.navigation {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 35px;
    margin-top: 15px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
    }
    &--pagination {
        display: flex;
        position: relative;
        a {
            color: #828282;
            background: #fff;
            &:hover {
                background: #ff8a00;
                color: #fff;
            }
        }
        span {
            background: #12426d;
            color: #fff;
            &.disabled {
                color: #828282;
                background: #fff;
            }
        }
        span,
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49px;
            height: 49px;
            font-family: $jet-font500 !important;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            border-radius: inherit;
            opacity: 1;
            text-decoration: none;
            @media screen and (max-width: 767px) {
                width: 30px;
                height: 30px;
                font-size: 14px;
                line-height: 17px;
            }
            @media screen and (max-width: 767px) {
                width: 30px;
                height: 30px;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    &--control {
        position: relative;
        display: flex;
        @media screen and (max-width: 767px) {
            margin: 15px 0 15px 10px;
        }
        .disabled {
            display: none;
        }
        &--prev {
            border-color: #c4c4c4;
            &:before {
                border-right-color: #c4c4c4;
            }
        }
        &--next {}
    }
}