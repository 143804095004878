#f9f9f9.districts-list ul li {
  &:hover {
    cursor: pointer;
    background: #e9eff3;
  }
}

.federation-page {
  background: url('../../img/registration/registration-bg.png') center center/cover no-repeat;
}

.federation {
  // max-width: 1750px;
  // margin: 0 auto;
  // padding: 20px 15px 80px;

  & > .container {
    padding-top: 20px;
    padding-bottom: 80px;

    @media screen and (max-width: 767px) {
      padding-bottom: 35px;
    }
  }

  &__content {
    margin-top: 20px;
  }

  h1 {
    color: #12426d;
    text-align: left;
    font-weight: 800;
    font-size: 2.6rem;
    line-height: 3rem;

    @media screen and (max-width: 1600px) {
      font-size: 2.4rem;
      line-height: 3rem;
    }

    @media screen and (max-width: 1200px) {
      font-size: 2rem;
      line-height: 2.5rem;
      max-width: 910px;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 15px;
    }

    @media screen and (max-width: 480px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .tabs {
    margin: 20px 0;
    // padding: 0 20px;
    @media screen and (max-width: 1024px) {
      padding: 0;
    }

    &-nav-wrap {
      position: relative;
    }

    &__nav {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 25px;
      margin-bottom: 40px;
      border-bottom: 2px solid #12426d;

      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
        display: none;

        &.active {
          position: absolute;
          top: 100%;
          display: block;
          width: 100%;
          padding: 15px;
          background: #f8f8f8;
          border: none;
          margin-top: 0;
          z-index: 20;

          li {
            button {
              margin-bottom: 20px;
              font-family: $jet-font400;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #12426d;
            }
          }
        }
      }

      li {
        padding-right: 5px;

        &:last-child {
          padding: 0;
        }

        button {
          border: none;
          background: transparent;
          font-family: $jet-font400;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: #12426d;
          transform: 0.5s;

          @media screen and (max-width: 1024px) {
            font-size: 16px;
            line-height: 20px;
          }

          &.active,
          &:hover {
            color: #ff8a00;
            padding-bottom: 3px;
            border-bottom: 2px solid #12426d;
            transform: 0.5s;
          }
        }
      }
    }

    .mob-selector {
      display: none;

      @media screen and (max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 40px;
        background: #f8f8f8;
        padding-left: 10px;
        border-bottom: 1px solid #12426d;
      }

      &--text {
        // position: relative;
        display: inline-block;
        width: 100%;
        border-right: 1px solid #12426d;

        &::before {
          // position: relative;
          content: attr(data-text);
          font-family: $jet-font400;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #12426d;
        }
      }

      &--arrow {
        position: relative;
        max-width: 37px;
        width: 100%;
        height: 40px;

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          height: 5px;
          border: 6px solid transparent;
          border-right: none;
          border-left: 8px solid #12426d;
          transition: all 0.3s;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
  }

  &__structure {
    display: flex;
    justify-content: center;
    width: 100%;

    &-bg {
      z-index: 1;

      .desktop {
        display: block !important;
      }

      .phone {
        display: none;
      }

      @media screen and (max-width: 767px) {
        .phone {
          display: block;
          height: 100%;
          width: auto;
        }

        .desktop {
          display: none !important;
        }
      }
    }
  }

  &__documents {
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    &.view-list {
      ul.federation__documents-list {
        li {
          padding: 0;

          span {
            display: none;
          }
        }
      }
    }

    &.view-block {
      ul.federation__documents-list {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;

        li {
          border: 1px #12426d solid;
          padding: 14px 30px 14px 40px;
          max-width: calc((100% - 50px) / 3);
          width: 100%;
          height: 100px;
          background: #fff;

          @media screen and (max-width: 1600px) {
            padding: 10px 20px;
          }
          @media screen and (min-width: 1201px) {
            margin-right: 25px;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
          }
          @media screen and (max-width: 1200px) {
            max-width: calc((100% - 30px) / 2);
            margin-right: 25px;
            &:nth-of-type(2n) {
              margin-right: 0;
            }
          }

          @media screen and (max-width: 767px) {
            max-width: 100%;
            padding: 10px;
            margin-right: 0;
            margin-bottom: 15px;
            height: 78px;
          }
        }
      }
    }

    &-view {
      display: flex;
      align-items: center;  
      // padding: 0 20px;
      margin-bottom: 20px;

      li {
        margin-right: 15px;
        cursor: pointer;

        svg {
          width: 30px;
          height: 30px;

          path,
          circle {
            fill: #12426d;
          }
        }

        &:hover,
        &.active {
          svg {
            path,
            circle {
              fill: #ff8a00;
            }
          }
        }
      }
    }

    &-list {
      width: 100%;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // flex-wrap: wrap;
    }

    &-item {
      display: flex;
      align-items: center;
      overflow: hidden;
      // padding: 14px 30px 14px 40px;
      // max-width: calc((100% - 50px) / 3);
      width: 100%;
      // height: 100px;
      // border: 1px #12426d solid;
      margin-bottom: 25px;
      // background: #fff;

      @media screen and (max-width: 1600px) {
        // padding: 10px 20px;
      }

      @media screen and (max-width: 1200px) {
        // max-width: calc((100% - 30px) / 2);
      }

      @media screen and (max-width: 767px) {
        max-width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        // height: 78px;
      }

      &:hover {
        a {
          p {
            color: #ff8a00;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        justify-content: flex-start;
        height: 100%;
      }

      span {
        padding-right: 40px;
        display: flex;
        align-items: center;
        height: 100%;
        border-right: 1px #12426d solid;

        @media screen and (max-width: 1600px) {
          padding-right: 25px;
        }

        @media screen and (max-width: 767px) {
          padding-right: 15px;
        }

        @media screen and (max-width: 767px) {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      p {
        color: #12426d;
        padding-left: 25px;
        font-family: $jet-font400;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.2;

        @media screen and (max-width: 1200px) {
          font-size: 0.9rem;
          line-height: 1.1;
        }

        @media screen and (max-width: 767px) {
          font-size: 0.8rem;
          line-height: 1;
          padding-left: 15px;
        }
      }
    }
  }

  &__disciplines {
    width: 100%;

    .subtabs__button-list {
      border: none;

      li {
        margin-right: 10px;
        margin-bottom: 10px;

        button {
          margin-right: 0;
          padding: 6px 25px;
          border: 1px #e0e0e0 solid;
          background: transparent;
          color: #12426d;
          font-family: JetBrainsMono-Regular;
          transition: 0.3s;
          box-sizing: border-box;

          &.active,
          &:hover {
            background: #12426d;
            color: #fff;
            border: 1px solid #12426d;
          }
        }
      }
    }

    &--discipline {
      max-width: 1200px;
      padding: 0 30px 40px;
      margin: 0 auto;

      h3 {
        font-family: $jet-font400;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.9rem;
        color: #12426d;
      }

      p {
        font-family: $jet-font400;
        font-size: 1rem;
        line-height: 1.5;
        color: #12426d;
        margin-top: 15px;
      }

      // img {
      //     // width: 100%;
      //     // height: auto;
      //     // margin-top: 20px;
      // }

      span {
        float: right;
        margin: 15px 0 15px 25px;

        img {
          margin-top: 0;
        }
      }

      @media screen and (max-width: 767px) {
        padding: 0 15px;

        span {
          float: none;

          img {
            margin-top: 20px;
          }
        }
      }
    }
  }

  &__management {
    display: flex;
    flex-direction: column;

    &-president {
      span {
        float: left;
        margin-right: 40px;
        padding-bottom: 30px;

        @media screen and (max-width: 767px) {
          margin: 0;
        }

        img {
          width: 100%;
          height: auto;
          // max-width: 410px;
          max-width: 300px;
          max-height: 450px;
          object-fit: contain;
        }
      }

      h3 {
        font-family: $rubik-font700;
        font-weight: 700;
        font-size: 2.2rem;
        color: #12426d;
        line-height: 2.5rem;

        @media screen and (max-width: 1200px) {
          font-size: 1.8rem;
          line-height: 2rem;
        }

        @media screen and (max-width: 480px) {
          font-size: 1.5rem;
          line-height: 1.8rem;
        }
      }

      h5 {
        font-family: $jet-font400;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.2;
        color: #828282;
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 554px;
        display: flex;
      }

      p {
        font-family: $jet-font400;
        font-size: 1rem;
        line-height: 1.5;
        color: #12426d;
        margin-top: 19px;
      }

      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;

        span {
          float: none;
          display: flex;
          justify-content: center;
        }

        p {
          font-size: 0.9rem;
          line-height: 1.5;
          margin-top: 10px;
        }
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      align-items: flex-start;
      margin-top: 30px;

      li {
        width: calc((100% - 75px) / 4);
        display: flex;
        // align-items: center;
        justify-content: flex-start;
        // flex-wrap: wrap;
        flex-direction: column;
        margin: 0 8px 40px;

        @media screen and (max-width: 1370px) {
          width: calc((100% - 50px) / 3);
        }

        @media screen and (max-width: 1024px) {
          width: calc((100% - 40px) / 2);
        }

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 30px;
        }

        .img-wrap {
          position: relative;
          width: 100%;
          margin-bottom: 25px;
          @media screen and (max-width: 767px) {
            margin-bottom: 15px;
          }

          &:before {
            display: block;
            content: '';
            padding-bottom: 120%;
          }

          img {
            position: absolute;
            left: 0;
            top: 0;

            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        h4 {
          font-family: $rubik-font400;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 1.5rem;
          color: #12426d;
          margin-bottom: 5px;

          @media screen and (max-width: 767px) {
            font-size: 1.1rem;
            line-height: 1.3rem;
            margin-bottom: 10px;
          }
        }

        h5 {
          font-family: $jet-font400;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.2;
          color: #828282;
          margin-bottom: 15px;

          @media screen and (max-width: 767px) {
            margin-bottom: 10px;
          }
        }

        p {
          font-family: $jet-font400;
          color: #12426d;
          font-size: 1rem;
          line-height: 1.2;

          @media screen and (max-width: 767px) {
            font-size: 0.9rem;
            line-height: 1.5;
          }
        }
      }
    }
  }

  &__referees {
    width: 100%;

    .federation-search-form {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__filters {
    max-width: 410px;

    &_districts-list {
      & > p {
        display: none;
      }

      ul {
        border: 1px solid #12426d;
        max-height: 420px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 5px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: #12426d;
          border-radius: 5px;
        }

        &.is-active {
          li {
            display: none;
          }
        }
      }

      li {
        // padding: 30px 36px 30px 30px;
        position: relative;

        p {
          font-family: $jet-font400;
          font-weight: 500;
          // width: 90%;
          width: 100%;
          padding: 30px 50px 30px 30px;
          color: #12426d;
          font-size: 1rem;
          line-height: 1.2;
          position: relative;

          &:before {
            content: '';
            width: 6px;
            height: 6px;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            border-radius: 50%;
          }
        }

        &.active {
          p {
            background: #e9eff3;
          }

          .list-district {
            display: block;
          }
        }

        &.hidden {
          display: none;
        }

        .list-district {
          display: none;

          &.active {
            display: block;
          }

          div {
            padding: 10px 30px 10px 50px;
            color: #12426d;
            font-size: 1rem;
            line-height: 1.2;

            &:hover {
              cursor: pointer;
            }

            &.active {
              background: #c9e9ff;
            }
          }
        }

        &:after {
          content: '';
          position: absolute;
          width: 85%;
          height: 1px;
          bottom: -1px;
          left: 50%;
          transform: translateX(-50%);
          background: #e0e0e0;
        }

        &:nth-of-type(1) {
          p:before {
            background: #aa6ca6;
          }
        }

        &:nth-of-type(2) {
          p:before {
            background: #fc8b8b;
          }
        }

        &:nth-of-type(3) {
          p:before {
            background: #01bee7;
          }
        }

        &:nth-of-type(4) {
          p:before {
            background: #37ce04;
          }
        }

        &:nth-of-type(5) {
          p:before {
            background: #62d2c5;
          }
        }

        &:nth-of-type(6) {
          p:before {
            background: #c7cb8f;
          }
        }

        &:nth-of-type(7) {
          p:before {
            background: #ffe600;
          }
        }

        &:nth-of-type(8) {
          p:before {
            background: #fece2c;
          }
        }

        &:last-of-type:after {
          content: none;
        }
      }

      .search-clubs {
        width: 100%;
        position: relative;
        margin-top: 15px;
        background: #e9eff3;
        position: relative;
        width: 100%;

        .clubs-wrap {
          display: none;
          position: absolute;
          max-height: 305px;
          width: 100%;
          background-color: #fff;
          border: 1px solid #12426d;
          z-index: 10;
          overflow-y: scroll;

          .club {
            display: flex;
            align-items: center;
            padding: 5px;
            font-family: $jet-font400;
            font-size: 0.8rem;
            line-height: 1.3;
            color: #12426d;

            &:hover {
              background-color: #12426d;
              color: #fff;
            }
          }
        }

        // #club:focus + .clubs-wrap {
        //     display: block;
        // }

        input {
          width: 100%;
          height: 65px;
          padding: 0 30px;
          font-family: $jet-font400;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.2;
          color: #12426d;
          border: 1px solid #12426d;

          &::placeholder {
            color: #828282;
          }
        }

        button {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          background: transparent;
          border: none;
        }

        &.search-clubs {
        }
      }
    }

    &_filter-list {
      margin-top: 55px;

      // form {
      border: 1px solid #12426d;
      background: #fff;

      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 30px 0;
        margin: 0 30px;
        border-bottom: 1px solid #bdbdbd;

        &:nth-of-type(2) {
          border: none;
        }

        p {
          margin-bottom: 5px;
          font-family: $jet-font400;
          font-weight: 700;
          font-size: 1rem;
          line-height: 1.2;
          color: #12426d;
        }

        label {
          margin-top: 15px;
          font-family: $jet-font400;
          font-size: 1rem;
          line-height: 1.2;
          display: flex;
          align-items: center;
          color: #12426d;
          position: relative;

          &:hover {
            cursor: pointer;
          }

          input {
            -webkit-appearance: checkbox;
            width: 25px;
            height: 25px;
            margin-right: 10px;
            padding: 5px;
            position: relative;
            z-index: 5;
            opacity: 0;

            &:hover {
              cursor: pointer;
            }
          }

          & span {
            position: absolute;
            width: 25px;
            height: 25px;
            border: 1px solid #12426d;
            left: 0;
            top: 0;
            // z-index: -1;

            &:before {
              content: url('../../img/federation/confirm.png');
              position: relative;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;
            }
          }

          input:checked ~ span {
            background: #12426d;

            &:before {
              opacity: 1;
            }
          }
        }

        // &:last-of-type {
        //     display: none;
        // }
      }

      // }
    }

    &_mob-buttons {
      display: none;
    }

    @media screen and (max-width: 1024px) {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100vh - 74px);
      overflow-y: auto !important;
      padding: 0 0 20px 0;
      z-index: 1000;
      overflow-y: scroll;

      &.show {
        display: block;
        background: #fff;

        max-width: none;
        overflow: hidden;
      }

      &_districts-list {
        max-width: 450px;
        margin: 0 auto;

        & > p {
          display: block;
          font-family: $jet-font400;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase;
          color: #12426d;
          font-weight: 700;
          padding: 5px 15px;
          margin-top: 25px;
        }

        ul {
          max-height: none;
          border: none;

          li {
            p {
              width: 100%;
              padding: 13px;

              &:before {
                right: 5px;
              }
            }

            &:after {
              width: 100%;
            }
          }
        }

        .search-clubs {
          display: none;
        }
      }

      &_filter-list {
        margin: 0 auto;

        // form {
        border: none;

        div {
          padding: 15px;
          max-width: 450px;
          margin: 0 auto;

          // &:last-of-type {
          //     background: #f9f9f9;
          //     display: flex;
          //     align-items: center;
          //     justify-content: center;
          //     max-width: none;

          //     button {
          //         margin: 15px;
          //         width: 290px;
          //         height: 37px;
          //         border: 1px solid #12426d;
          //         font-size: 14px;
          //         font-family: $jet-font400;
          //         line-height: 17px;
          //         color: #12426d;
          //         background: none;
          //     }
          // }
        }

        // }
      }

      &_mob-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #12426d;

        a:first-of-type {
          font-size: 14px;
          font-family: $jet-font400;
          line-height: 17px;
          color: #12426d;
          position: relative;
          padding: 0 10px;
          background: none;
          border: none;

          &:before {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            top: 4px;
            left: 0;
            border-bottom: 1px solid #12426d;
            border-left: 1px solid #12426d;
            transform: rotate(45deg);
          }
        }

        a:last-of-type {
          font-size: 12px;
          font-family: $jet-font400;
          line-height: 15px;
          color: #12426d;
          text-transform: uppercase;
          text-decoration: underline;
          background: none;
          border: none;
        }
      }
    }
  }

  &__result {
    max-width: 1284px;
    width: 100%;
    padding-left: 25px;

    &_map {
      max-width: 860px;
      width: 100%;
      margin: 0 auto;

      svg {
        .location {
          transition: 0.3s;
          position: relative;

          &:hover {
            cursor: pointer;
            opacity: 0.5;
          }

          &.hidden {
            fill: #fff !important;
          }

          @media screen and (max-width: 1024px) {
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    &_view {
      padding: 30px 0 15px;
      color: #12426d;
      font-family: $jet-font400;

      &-buttons,
      &_submit {
        display: none;
      }

      &--wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &_inputs {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &.mob {
          display: none;
        }
      }

      label {
        margin-right: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      input {
        margin: 0 20px;
        appearance: none;
        min-width: 24px;
        width: 24px;
        height: 24px;
        border: 1px #12426d solid;
        position: relative;
        background: #fff;
        border-radius: 50%;
        outline: none;

        &::before {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #12426d;
          border-radius: 50%;
          visibility: hidden;
        }

        &:checked:before {
          visibility: visible;
        }
      }

      @media screen and (max-width: 1024px) {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        overflow-y: scroll;

        &.show {
          background: #fff;
          display: block;
          max-width: none;
          overflow: hidden;
          padding: 0;
          height: 100vh;
        }
        &-buttons {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px;
          border-bottom: 1px solid #12426d;
          width: 100%;

          a:first-of-type {
            font-size: 14px;
            font-family: $jet-font400;
            line-height: 17px;
            color: #12426d;
            position: relative;
            padding: 0 10px;
            background: none;
            border: none;

            &:before {
              position: absolute;
              content: '';
              width: 8px;
              height: 8px;
              top: 4px;
              left: 0;
              border-bottom: 1px solid #12426d;
              border-left: 1px solid #12426d;
              transform: rotate(45deg);
            }
          }

          a:last-of-type {
            font-size: 12px;
            font-family: $jet-font400;
            line-height: 15px;
            color: #12426d;
            text-transform: uppercase;
            text-decoration: underline;
            background: none;
            border: none;
          }
        }
        &--wrap {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          height: 100%;
          position: relative;
          overflow-y: scroll;
        }
        &_inputs {
          flex-direction: column;
          width: 100%;
          align-items: flex-start;
          padding: 20px 15px;
          border-bottom: 1px #12426d solid;

          label {
            margin: 10px 0;
          }

          &.mob {
            display: flex;
            border: none;
          }
        }
        .federation__result_quantity {
          display: none;
        }
        &_submit {
          position: static;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f9f9f9;
          width: 100%;
          margin-top: auto;

          button {
            margin: 15px;
            width: 290px;
            height: 37px;
            border: 1px solid #12426d;
            font-size: 14px;
            font-family: $jet-font400;
            line-height: 17px;
            color: #12426d;
            background: #fff;
          }
        }
      }
    }

    &_quantity {
      position: relative;
      transition: 0s;
      display: flex;
      align-items: center;

      p {
        margin-right: 20px;
      }

      a {
        width: 90px;
        text-decoration: none;
        color: #12426d;
        border-bottom: 1px #12426d solid;
        font-family: $jet-font400;
        font-weight: 700;
        line-height: 20px;
        background: #f8f8f8;
        padding: 7px 15px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        transition: 0.3s;
      }

      span {
        padding-left: 7px;
        position: relative;
        width: 25px;
        border-left: 1px solid #12426d;

        &:before {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          width: 8px;
          height: 4px;
          transform: translateY(-50%);
          border: 6px solid transparent;
          border-bottom: none;
          border-top: 8px solid #12426d;
        }
      }

      &-list {
        display: none;
        transition: 0s;
        background: #fff;
        padding: 0 15px;
        position: absolute;
        width: 90px;
        top: 100%;
        right: 0;

        li {
          font-size: 16px;
          line-height: 20px;
          font-family: $jet-font400;
          font-weight: 700;
          padding: 9px 0;
          color: #12426d;

          &:hover {
            color: #ff8a00;
            cursor: pointer;
          }
        }
      }

      &:hover {
        & > a {
          color: #ff8a00;
        }
      }
    }

    &_mob-buttons {
      display: none;
    }

    &_list {
      margin-top: 26px;

      ul {
        display: flex;
        border-bottom: 1px solid #a7bed2;
        padding: 5px 0;

        @media screen and (max-width: 767px) {
          padding: 0;
        }

        &:first-child {
          border: none;
        }

        li {
          display: flex;
          align-items: center;
          width: calc(100% / 3);
          min-height: 50px;
          padding-right: 10px;
          text-align: left;
          font-family: $jet-font500;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;

          @media screen and (max-width: 1024px) {
            font-size: 12px;
            line-height: 16px;
          }

          &:first-child {
            padding-left: 30px;

            @media screen and (max-width: 1024px) {
              padding-left: 10px;
            }
          }
        }
      }

      .title {
        background: #12426d;

        li {
          text-transform: uppercase;
          color: #fff;

          @media screen and (max-width: 767px) {
            &:nth-child(1) {
              width: 80%;
            }

            &:nth-child(2) {
              width: 20%;
              text-align: right;
              justify-content: flex-end;
            }

            &:nth-child(3) {
              display: none;
            }

            &:nth-child(4) {
              display: none;
            }
          }
        }
      }

      .people {
        @media screen and (max-width: 767px) {
          flex-wrap: wrap;
        }

        li {
          color: #12426d;

          @media screen and (max-width: 767px) {
            width: 100%;
            padding-left: 10px;
            border-bottom: 1px solid #a7bed2;

            &:nth-child(1) {
              width: 70%;
              position: relative;

              &::before {
                content: '';
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                height: 60%;
                border-right: 1px solid #a7bed2;
              }
            }

            &:nth-child(2) {
              width: 30%;
              text-align: right;
              justify-content: flex-end;
            }

            &:nth-child(3),
            &:nth-child(4) {
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;

              &::before {
                content: attr(data-title);
                font-family: $jet-font500;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
                color: #12426d;
              }
            }
          }
        }
      }

      .navigation {
        justify-content: center;
      }
    }

    @media screen and (max-width: 1024px) {
      padding-left: 0;

      &_mob-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 290px;
        margin: 0 auto;
        margin-top: 30px;

        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 37px;
          width: calc((100% - 10px) / 2);
          background: #fff;
          border: 1px solid #12426d;
          font-family: $jet-font400;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.2;
          color: #12426d;
        }

        &--search {
          width: 100%;
          max-width: 100%;
          position: relative;
          margin-top: 15px;
          background: #e9eff3;

          input {
            width: 100%;
            height: 55px;
            padding: 0 30px;
            font-family: $jet-font400;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.2;
            color: #12426d;
            border: 1px solid #12426d;

            &::placeholder {
              color: #828282;
            }
          }

          button {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            background: transparent;
            border: none;
          }
        }
      }
    }
  }

  &__history {
    max-width: 1200px;
    padding: 0 30px 40px;
    margin: 0 auto;

    h3 {
      font-family: $jet-font400;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.9rem;
      color: #12426d;
    }

    p {
      font-family: $jet-font400;
      font-size: 1rem;
      line-height: 1.5;
      color: #12426d;
      margin-top: 15px;
    }

    img {
      // width: 100%;
      // height: auto;
      // margin-top: 20px;
    }

    span {
      float: right;
      margin: 15px 0 15px 25px;

      img {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 0 15px;

      span {
        float: none;

        img {
          margin-top: 20px;
        }
      }
    }
  }
}
