.clubs-page {
  background: url('../../img/registration/registration-bg.png') center center/cover no-repeat;
}

.clubs {
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    margin-bottom: 35px;
  }

  .container {
    padding: 0;
  }

  h1 {
    color: #12426d;
    text-align: left;
    font-weight: 800;
    font-size: 2.6rem;
    line-height: 3rem;
    width: 100%;
    margin-bottom: 30px;
    @media screen and (max-width: 1600px) {
      font-size: 2.4rem;
      line-height: 3rem;
    }
    @media screen and (max-width: 1200px) {
      font-size: 2rem;
      line-height: 2.5rem;
      max-width: 910px;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .bread-crumbs {
    max-width: 1750px;
    width: 100%;
    padding: 21px 0 42px;
    margin: 0 auto;
  }

  &__wrapper {
    max-width: 1750px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .wrap-districts-block {
    width: 25%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &__districts-block {
    ul {
      border: 1px solid #12426d;
      max-height: 420px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #12426d;
        border-radius: 5px;
      }
    }

    & > p {
      display: none;
    }

    li {
      padding: 30px 36px 30px 30px;
      position: relative;
      cursor: pointer;

      p {
        font-family: $jet-font400;
        font-weight: 500;
        width: 90%;
        color: #12426d;
        font-size: 1rem;
        line-height: 1.2;
        position: relative;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          right: -12%;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          border-radius: 50%;
        }
      }

      &.active {
        background: #e9eff3;
      }

      &:after {
        content: '';
        position: absolute;
        width: 85%;
        height: 1px;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        background: #e0e0e0;
      }

      &:nth-of-type(1) {
        p:before {
          background: #aa6ca6;
        }
      }

      &:nth-of-type(2) {
        p:before {
          background: #fc8b8b;
        }
      }

      &:nth-of-type(3) {
        p:before {
          background: #01bee7;
        }
      }

      &:nth-of-type(4) {
        p:before {
          background: #37ce04;
        }
      }

      &:nth-of-type(5) {
        p:before {
          background: #62d2c5;
        }
      }

      &:nth-of-type(6) {
        p:before {
          background: #c7cb8f;
        }
      }

      &:nth-of-type(7) {
        p:before {
          background: #ffe600;
        }
      }

      &:nth-of-type(8) {
        p:before {
          background: #fece2c;
        }
      }

      &:last-of-type:after {
        content: none;
      }
    }

    .search-clubs {
      width: 100%;
      position: relative;
      margin-top: 15px;
      background: #e9eff3;

      input {
        width: 100%;
        height: 55px;
        padding: 0 35px 0 15px;
        font-family: $jet-font400;
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 1.2;
        color: #12426d;
        border: 1px solid #12426d;

        &::placeholder {
          color: #828282;
        }
      }

      button {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        background: transparent;
        border: none;
      }
    }

    .search-clubs {
      position: relative;
      width: 100%;

      .clubs-wrap {
        display: none;
        position: absolute;
        max-height: 305px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #12426d;
        z-index: 10;
        overflow-y: scroll;

        .club {
          display: flex;
          align-items: center;
          padding: 5px;
          font-family: $jet-font400;
          font-size: 0.8rem;
          line-height: 1.3;
          color: #12426d;

          &:hover {
            background-color: #12426d;
            color: #fff;
          }
        }
      }

      // #club:focus + .clubs-wrap {
      //     display: block;
      // }
    }

    &_submit {
      background: #f9f9f9;
      display: none;
      align-items: center;
      justify-content: center;

      button {
        margin: 15px;
        width: 290px;
        height: 37px;
        border: 1px solid #12426d;
        font-size: 14px;
        font-family: $jet-font400;
        line-height: 17px;
        color: #12426d;
        background: none;
      }
    }

    @media screen and (max-width: 1024px) {
      max-width: 450px;
      margin: 0 auto;
      display: none;
      & > p {
        display: block;
        font-family: $jet-font400;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #12426d;
        font-weight: 700;
        padding: 15px;
        margin-top: 20px;
      }
      ul {
        border: none;

        li {
          padding: 13px;

          p {
            width: 100%;

            &:before {
              right: 0;
            }
          }

          &:after {
            width: 100%;
          }
        }
      }
      form {
        display: none;
      }
      &.show {
        display: block;
        width: 100%;
        max-width: none;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
        position: absolute;
        background: #fff;
      }
      &_submit {
        display: flex;
      }
    }
  }

  &__map-block {
    max-width: 1020px;
    width: 64%;
    margin: 0 auto;
    padding-top: 20px;

    svg {
      .location {
        transition: 0.3s;

        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }

        &.hidden {
          fill: #fff !important;
        }

        @media screen and (max-width: 1024px) {
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &__mob-buttons {
    display: none;

    &_upper {
      display: none;
    }

    .search-clubs {
      position: relative;
      width: 100%;

      .clubs-wrap {
        display: none;
        position: absolute;
        max-height: 305px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #12426d;
        z-index: 10;
        overflow-y: scroll;

        .club {
          display: flex;
          align-items: center;
          padding: 5px;
          font-family: $jet-font400;
          font-size: 0.8rem;
          line-height: 1.3;
          color: #12426d;

          &:hover {
            background-color: #12426d;
            color: #fff;
          }
        }
      }

      // #club:focus + .clubs-wrap {
      //     display: block;
      // }
    }

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      max-width: 290px;
      width: 100%;
      margin: 0 auto;
      margin-top: 30px;
      button {
        height: 37px;
        background: #fff;
        border: 1px solid #12426d;
        font-family: $jet-font400;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.2;
        color: #12426d;
      }
      .search-clubs {
        width: 100%;
        position: relative;
        margin-top: 15px;
        background: #e9eff3;

        input {
          width: 100%;
          height: 37px;
          padding: 0 35px 0 15px;
          font-family: $jet-font400;
          font-weight: 500;
          font-size: 0.7rem;
          line-height: 1.2;
          color: #12426d;
          border: 1px solid #12426d;

          &::placeholder {
            color: #828282;
          }
        }

        button {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          background: transparent;
          border: none;
        }
      }
      &_upper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #12426d;

        button:first-of-type {
          font-size: 14px;
          font-family: $jet-font400;
          line-height: 17px;
          color: #12426d;
          position: relative;
          padding: 0 10px;
          background: none;
          border: none;

          &:before {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            top: 4px;
            left: 0;
            border-bottom: 1px solid #12426d;
            border-left: 1px solid #12426d;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  &__list {
    margin-top: 30px;
    width: 100%;

    ul {
      display: flex;
      flex-direction: column;

      li {
        display: grid;
        grid-template-columns: 15% 85%;
        grid-template-rows: 1fr 3fr;
        // display: flex;
        border: 1px solid #12426d;
        margin-bottom: 25px;
        min-height: 200px;
        // img {
        //   grid-column-start: 1;
        //   grid-column-end: 2;
        //   grid-row-start: 1;
        //   grid-row-end: 3;
        //   min-height: 197px;
        //   max-height: 197px;
        //   width: 100%;
        //   height: 100%;
        //   object-fit: cover;
        // }
      }

      @media screen and (max-width: 1024px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        li {
          display: flex;
          flex-direction: column;
          width: calc((100% - 30px) / 3);
          margin-right: 10px;
          margin-bottom: 10px;

          // img {
          //   max-height: 200px;
          //   height: auto;
          //   border-top: 1px solid #e2e2e2;
          //   border-bottom: 1px solid #e2e2e2;
          // }
        }
      }
      @media screen and (max-width: 767px) {
        li {
          width: calc((100% - 30px) / 2);
          margin-right: 15px;
        }
      }
      @media screen and (max-width: 480px) {
        li {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  &__item-img {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    align-items: center;
    min-height: 197px;
    max-width: 260px;
    margin: 0 auto;  
    width: 100%;
    border-right: 1px solid #12426d;
    @media screen and (max-width: 1024px) {
      max-height: 200px;
      max-width: 200px;
      height: auto;
      border-top: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;
      border-right: none;
    }
    .img-wrap {
      position: relative;
      width: 100%;
      &::before {
        display: block;
        content: '';
        padding-bottom: 100%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    
  }

  &__item-title {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #12426d;

    h5 {
      color: #fff;
      font-family: $rubik-font400;
      font-weight: 500;
      font-size: 1.1rem;
      line-height: 1.5rem;
      width: 75%;
      padding-left: 30px;
    }

    p {
      font-family: $jet-font400;
      font-size: 1rem;
      line-height: 1.2rem;
      color: #fff;
      padding-left: 30px;
      border-left: 1px solid #2f628e;
      width: 25%;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      background: none;
      h5 {
        width: 100%;
        font-size: 1rem;
        line-height: 1.2;
        background: #12426d;
        padding: 10px 15px;
        height: 65px;
        display: flex;
        align-items: center;
      }
      p {
        width: 100%;
        padding: 10px 16px;
        color: #12426d;
        font-size: 0.9rem;
        border: none;
      }
    }
  }

  &__item-info {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

    div {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 30px;
      height: 100%;
      border-right: 1px solid #bdbdbd;

      p {
        max-width: 80%;
        letter-spacing: 1px;
      }

      p:first-of-type {
        color: #828282;
        font-family: $jet-font400;
        font-size: 1rem;
        line-height: 1.2rem;
        margin: 10px 0;
      }

      p:last-of-type {
        font-family: $rubik-font400;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.2rem;
        color: #12426d;
      }
    }

    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      padding: 0;
      div {
        width: 50%;
        height: auto;
        margin-bottom: 10px;
        border: none;

        p {
          max-width: 100%;

          &:first-of-type {
            margin: 0;
            margin-bottom: 5px;
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      div {
        width: 100%;
        margin: 5px 10px;
        padding: 10px 0;
        border: none;
        border-bottom: 1px #898989 solid;

        p:first-of-type {
          font-size: 0.9rem;
          margin-bottom: 5px;
          line-height: 1.1;
        }

        p:last-of-type {
          font-size: 0.9rem;
          line-height: 1.1;
        }

        &:last-of-type {
          border: none;
        }
      }
    }
  }
}
