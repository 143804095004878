.news-page {
  background: url('../../img/registration/registration-bg.png') center center/cover no-repeat;

  .tabs__button-list {
    margin: 0;
  }

  .news-wrap {
    padding-bottom: 50px;
    @media screen and (max-width: 767px) {
      padding-bottom: 15px;
    }

    .news-cards {
      margin-top: 30px;

      li {
        display: block;
      }

      .js-tabs-item {
        display: none;

        &.active {
          display: block;
        }
      }

      .news-card {
        display: flex;
        margin-bottom: 35px;
        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }

        &:last-child {
          margin: 0;
        }

        &--img {
          position: relative;
          max-width: 557px;
          // width: 100%;
          // max-height: 370px;
          // height: 100%;
          width: 35%;
          height: 370px;
          margin-right: 25px;
          @media screen and (max-width: 1024px) {
            height: 300px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            height: 200px;
            margin: 0;
          }

          &::before {
            content: '';
            position: absolute;
            height: 40%;
            width: 100%;
            opacity: 0.1;
            bottom: 0;
            left: 0;
            z-index: 4;
            border-top: #fff0 230px solid;
            border-right: #fff0 62px solid;
            border-left: #fff solid 150px;
          }

          &::after {
            content: '';
            position: absolute;
            height: 40%;
            width: 100%;
            opacity: 0.1;
            top: 0;
            right: 0;
            z-index: 3;
            border-bottom: #fff0 230px solid;
            border-left: #fff0 62px solid;
            border-right: #fff solid 150px;
          }

          img {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            &:nth-child(2) {
              position: absolute;
              width: 100%;
              height: auto;
              max-width: 60px;
              right: 20px;
              top: 20px;
            }
          }
        }

        &--prev {
          width: 65%;
          padding: 25px 0;
          border-bottom: 2px solid #12426d;
          @media screen and (max-width: 1024px) {
            padding: 20px 0;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }

          .title {
            display: block;
            margin-bottom: 20px;
            text-decoration: none;
            font-family: $rubik-font700;
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
            color: #12426d;
            @media screen and (max-width: 1024px) {
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 15px;
            }

            &:hover {
              color: #ff8a00;
            }
          }

          .info {
            display: flex;
            margin-bottom: 23px;
            @media screen and (max-width: 1024px) {
              margin-bottom: 15px;
            }

            &--date,
            &--views {
              display: flex;
              align-items: center;

              img {
                margin-right: 15px;
                max-width: 24px;
                max-height: 20px;
              }

              span {
                font-family: $jet-font500;
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                color: #828282;
                @media screen and (max-width: 1024px) {
                  font-size: 12px;
                }
              }
            }

            &--date {
              margin-right: 20px;
            }
          }

          .text {
            margin-bottom: 25px;
            @media screen and (max-width: 1024px) {
              margin-bottom: 20px;
            }

            p {
              font-family: $jet-font400;
              font-size: 18px;
              line-height: 150%;
              color: #12426d;
              @media screen and (max-width: 1024px) {
                font-size: 14px;
                line-height: 150%;
              }
            }
          }

          .more {
            width: 260px;
            height: 40px;
            @media screen and (max-width: 767px) {
              margin: 0 auto;
            }

            &::before {
              background: #fff;
              border: 2px solid #e0e0e0;
              transition: 0.3s;
            }

            &:after {
              display: none;
            }

            &:hover {
              &::before {
                background: #12426d;
                border: 2px solid #12426d;
                transition: 0.3s;
              }

              p {
                color: #ff8a00;
                transition: 0.3s;
              }
            }

            p {
              font-family: $jet-font700;
              font-weight: 700;
              font-size: 14px;
              line-height: 130%;
              text-transform: uppercase;
              color: #12426d;
              transition: 0.3s;
            }
          }
        }
      }
    }

    .tabs {
      @media screen and (max-width: 767px) {
        margin: 20px 0 0;
      }
    }

    .navigation {
      // margin-top: 50px;
      @media screen and (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
}

.one-news-page {
  background: url('../../img/registration/registration-bg.png') center center/cover no-repeat;

  .article {
    &__tilte {
      padding: 0 0 40px;
      font-family: $rubik-font700;
      font-weight: 700;
      font-size: 42px;
      line-height: 50px;
      border-bottom: 2px solid #12426d;
      @media screen and (max-width: 1370px) {
        font-size: 34px;
        line-height: 40px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 26px;
        line-height: 30px;
      }
      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &__img {
      position: relative;
      margin-top: 25px;

      &::before {
        content: '';
        position: absolute;
        height: 40%;
        width: 100%;
        opacity: 0.1;
        bottom: 0;
        left: 0;
        z-index: 4;
        border-top: #fff0 230px solid;
        border-right: #fff0 62px solid;
        border-left: #fff solid 150px;
      }

      &::after {
        content: '';
        position: absolute;
        height: 40%;
        width: 100%;
        opacity: 0.1;
        top: 0;
        right: 0;
        z-index: 3;
        border-bottom: #fff0 260px solid;
        border-left: #fff0 62px solid;
        border-right: #fff solid 210px;
      }

      @media screen and (max-width: 767px) {
        &::before,
        &::after {
          display: none;
        }
      }

      img {
        width: 100%;
        max-height: 668px;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__gallery {
      // display: flex;
      // flex-wrap: wrap;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 15px;
      margin: 15px 0;
      @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
      }
      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
        gap: 5px;
      }

      .img-wrap {
        position: relative;
        // width: 25%;
        &::before {
          display: block;
          content: '';
          padding-bottom: 56.25%;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          object-fit: cover;
          object-position: center;
        }
      }
    }

    &__video {
      position: relative;
      max-width: 880px;
      width: 100%;
      margin: 15px auto;

      &::before {
        display: block;
        content: '';
        padding-bottom: 56.25%;
      }

      iframe,
      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__text {
      max-width: 1140px;
      width: 100%;
      margin: 35px auto 50px;

      .sub-title {
        margin-bottom: 20px;
        font-family: $jet-font700;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        color: #12426d;
      }

      p {
        margin-bottom: 20px;
        font-family: $jet-font400;
        font-size: 18px;
        line-height: 150%;
        color: #12426d;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      img {
        max-width: 100%;
      }

      ul,
      ol {
        margin-left: 20px;

        li {
          position: relative;
          margin-bottom: 20px;
          padding-left: 25px;
          font-family: $jet-font400;
          font-size: 18px;
          line-height: 150%;
          color: #12426d;
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }

      ul {
        li {
          &:before {
            position: absolute;
            left: 0;
            top: 1px;
            content: url('../../img/news/icn-li.png');
          }
        }
      }

      ol {
        list-style: none;
        counter-reset: list;

        li {
          &:before {
            position: absolute;
            left: 0;
            top: 3px;
            counter-increment: list;
            content: counter(list) '.';
            font-family: $jet-font700;
            font-weight: 800;
            font-size: 14px;
            line-height: 150%;
            color: #12426d;
          }
        }
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;
      border-top: 2px solid #12426d;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .info {
        display: flex;
        margin-right: 10px;
        @media screen and (max-width: 767px) {
          margin-bottom: 25px;
        }

        &--date,
        &--views {
          display: flex;
          align-items: center;

          img {
            margin-right: 15px;
            max-width: 24px;
            max-height: 20px;
          }

          span {
            font-family: $jet-font500;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: #828282;
            @media screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }

        &--date {
          margin-right: 20px;
        }
      }

      a {
        position: relative;
        padding-left: 20px;
        font-family: $jet-font700;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        text-transform: uppercase;
        text-decoration: none;
        color: #12426d;

        &:hover {
          color: #ff8a00;
        }

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          height: 5px;
          border: 6px solid transparent;
          border-right: none;
          border-left: 8px solid #12426d;
          transition: all 0.3s;
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }
}
