.tabs {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    width: 100%;
    &__button-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px;
        li {
            margin-right: 10px;
            margin-bottom: 10px;
            label {
                display: block;
                input {
                    display: none;
                }
            }
        }
        button,
        a,
        label span {
            display: block;
            padding: 6px 25px;
            border: 1px #E0E0E0 solid;
            background: transparent;
            color: #12426D;
            font-family: $jet-font400;
            transition: 0.3s;
            box-sizing: border-box;
            text-decoration: none;
            &.active {
                background: #12426D;
                color: #fff;
                border: 1px solid #12426D;
            }
            @media screen and (min-width:1024px) {
                &:hover {
                    cursor: pointer;
                    border: 1px solid #12426D;
                }
            }
        }
        label input[type=radio]:checked+span {
            background: #12426D;
            color: #fff;
            border: 1px solid #12426D;
        }
    }
}

.subtabs {
    &__button-list {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        border-bottom: 2px solid #12426D;
        flex-wrap: wrap;
        margin-bottom: 30px;
        @media screen and (max-width: 480px) {
            border: none;
        }
        button,
        label span {
            display: block;
            border: none;
            background: none;
            margin-right: 30px;
            margin-top: 10px;
            font-family: $jet-font400;
            font-size: 1rem;
            line-height: 1.2rem;
            color: #828282;
            padding-bottom: 5px;
            // transition: 0.3s;
            &.active,
            &:hover {
                cursor: pointer;
                color: #FF8A00;
                border-bottom: 3px solid #12426D;
                font-weight: bold;
            }
            @media screen and (max-width: 480px) {
                padding: 4px 8px;
                background: #8BA1B5;
                margin-right: 6px;
                color: #fff;
                &.active,
                &:hover {
                    cursor: pointer;
                    background: #12426D;
                    color: #fff;
                    order: none;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        top: 0;
                        left: 0;
                        border-right: 1px #fff0 solid;
                        border-bottom: 8px #fff0 solid;
                        border-left: 8px #62d2c5 solid;
                        box-sizing: border-box;
                    }
                }
            }
        }
        label {
            input {
                display: none;
            }
        }
        label input[type=radio]:checked+span {
            color: #FF8A00;
            border-bottom: 3px solid #12426D;
            font-weight: bold;
            @media screen and (max-width: 480px) {
                background: #12426D;
                color: #fff;
                order: none;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    top: 0;
                    left: 0;
                    border-right: 1px #fff0 solid;
                    border-bottom: 8px #fff0 solid;
                    border-left: 8px #62d2c5 solid;
                    box-sizing: border-box;
                }
            }
        }
    }
}