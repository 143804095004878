.section-bg{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  & img{
    position: relative;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    &:nth-of-type(2){
      top: unset;
      bottom: 0;
    }
  }
  & div{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 50px 0;
    & span {
      display: block;
      width: 100%;
      height: 100%;
      background: #4b1668;
    }
  }
}