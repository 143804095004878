.registration-page {
  background: url('../../img/registration/registration-bg.png') center center no-repeat;
}

.reset-password {
  label {
    margin: 25px auto 0 !important;
  }

  .btn {
    margin-top: 25px !important;
  }
}

.registration {
  max-width: 1200px;
  padding: 40px 30px 70px;
  margin: 0 auto;

  h1 {
    color: #12426d;
    text-align: center;
    font-weight: 800;
    font-size: 2.6rem;
    line-height: 3rem;
  }

  h3,
  input,
  select,
  .select2-selection__rendered {
    font-family: $jet-font400;
    font-size: 1.1rem;
    line-height: 1.3;
    text-align: center;
    color: #12426d;
    border-radius: unset;
  }

  .select2-selection__rendered {
    font-size: 1.1rem;
    line-height: 1.3;
    color: #12426d !important;
    text-align: left;
  }

  h3 {
    max-width: 810px;
    font-weight: 400;
    margin: 20px auto 10px;
  }

  p {
    font-family: $jet-font400;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.3;
    color: #12426d;
    width: 100%;
    position: relative;
    padding-right: 15px;

    &:before {
      content: '*';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #eb5757;
      font-size: 1.1rem;
    }
  }

  label,
  &__radio-box {
    max-width: 362px;
    width: 100%;
    margin-right: 25px;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }

  label {
    justify-content: flex-start;
  }

  label:last-of-type {
    margin-right: 0;
  }

  input[type='date'] {
    cursor: pointer;
  }

  input,
  select,
  .select2-selection {
    height: 65px;
    width: 100%;
    border: 1px solid #12426d;
    background: #fff;
    padding: 0 25px;
    text-align: left;
    margin-top: 7px;
    cursor: pointer;

    &::placeholder {
      color: #bdbdbd;
      font-family: $jet-font400;
      font-size: 1.1rem;
      line-height: 1.1;
    }
  }

  select,
  .select2-selection {
    appearance: none;
    padding: 0 40px 0 25px;
  }

  .select2-selection {
    display: flex;
    align-items: center;
  }

  .select2-selection__arrow {
    display: none;
  }

  .arrow {
    position: absolute;
    bottom: 25px;
    right: 25px;
    width: 8px;
    height: 8px;
    border-top: 5px solid #12426d;
    border-left: 5px solid #fff0;
    border-right: 5px solid #fff0;
  }

  &__personal-data {
    border-bottom: 1px solid #bdbdbd;
    padding: 0 0 25px;
    @media screen and (max-width: 767px) {
      max-width: 500px;
      margin: 0 auto;
    }

    & > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }
  }

  &__radio-box {
    label {
      width: calc(50% - 12px);
      margin-top: 0;
      margin-right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid #12426d;
      height: 65px;
      padding: 22px 25px 22px 20px;
      font-family: $jet-font400;
      font-size: 1.1rem;
      line-height: 1.3;
      color: #12426d;
      min-width: 161px;
      margin-top: 7px;

      input {
        -webkit-appearance: radio;
        width: 20px;
        height: 20px;
        margin-right: 15px;
        border: none;
        margin-top: 0;
      }
    }
  }

  &__contact-data,
  &__club-data {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 25px;
  }

  &__weapon-data {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    border: none;
    // padding: 17px 0 0;
    margin: 25px 0 35px;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background: #bdbdbd;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    label {
      max-width: none;
    }
  }

  &__weapon-box,
  &__ammo-box {
    width: calc(50% - 26px);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;

      button {
        max-width: 178px;
        width: 100%;
        height: 40px;
        background: #fff;
        border: 1px solid #12426d;
        transition: 0.5s;
        margin-top: 15px;
        cursor: unset;

        p {
          text-transform: uppercase;
          font-family: $jet-font500;
          color: #12426d;
          font-weight: 500;
          font-size: 14px;
          line-height: 1.3;
          margin-bottom: 0;

          &:before {
            content: '+';
            color: #12426d;
            position: static;
            font-size: 14px;
            margin-right: 10px;
          }
        }

        &.active:hover {
          background: #12426d;
          cursor: pointer;

          p {
            color: #fff;

            &:before {
              color: #fff;
            }
          }
        }

        &:last-of-type {
          display: none;

          p {
            padding: 0;

            &:before {
              content: none;
            }
          }

          &.show {
            display: block;
          }
        }
      }
    }
  }

  &__check-box {
    max-width: 385px;
    margin: 30px auto 0;

    label {
      width: 100%;
      display: flex;
      align-items: center;
      margin-right: 0;
      max-width: none;
      flex-wrap: wrap;
      cursor: pointer;

      input {
        -webkit-appearance: checkbox;
        width: 25px;
        height: 25px;
        margin: 0 15px 0 0;
        cursor: pointer;
      }

      p {
        width: 89%;
        font-size: 12px;
        @media screen and (max-width: 767px) {
          width: 85%;
        }

        &:before {
          content: none;
        }

        &.error {
          width: 100%;
        }
      }
    }
  }

  & .btn {
    max-width: 385px;
    width: 100%;
    border: 1px #fff0 solid;
    margin: 0 auto;

    p {
      font-family: $rubik-font700;
      color: #fff;

      &:before {
        content: none;
      }
    }

    &:after {
      border: 2px solid #e0e0e0;
    }
  }

  p.error {
    // position: absolute;
    // top: 100%;
    // left: 0;
    width: 100%;
    background: #eb5757;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.7rem;
    z-index: 20;

    &:before {
      content: none;
    }
  }

  @media screen and (max-width: 1024px) {
    p {
      font-size: 1rem;
      padding-right: 15px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 10px 15px 60px;
    h1 {
      font-size: 1.5rem;
      line-height: 1.9rem;
    }
    h3 {
      font-size: 0.9rem;
      line-height: 1.2rem;
      margin-top: 10px;
    }
    &__personal-data {
      & > div {
        flex-direction: column;
      }
    }
    &__contact-data {
      flex-direction: column;
    }
    &__club-data {
      flex-direction: column;
    }
    &__weapon-data {
      flex-direction: column;
      margin-top: 0;
      padding-top: 0;

      &:before {
        content: none;
      }
    }
    label,
    label:last-of-type,
    &__radio-box,
    &__weapon-box,
    &__ammo-box {
      width: 100%;
      max-width: 500px;
      margin: 15px auto 0;
    }
    &__weapon-box,
    &__ammo-box {
      label,
      label:last-of-type {
        padding: 0;
      }

      div {
        button {
          margin-top: 15px;
        }
      }
    }
    input {
      height: 49px;
      padding: 0 15px;

      &::placeholder {
        font-size: 16px;
      }
    }
    select {
      height: 49px;
      padding: 0 25px 0 15px;

      &::placeholder {
        font-size: 16px;
      }
    }
    & .btn {
      max-width: 320px;
    }
    .arrow {
      bottom: 18px;
      right: 10px;
    }
  }
  @media screen and (max-width: 480px) {
    &__weapon-data {
      button {
        max-width: none;
        margin-top: 5px;
        border-color: #bdbdbd;
      }
    }
    & .btn {
      max-width: 250px;
      font-size: 15px;
    }
    p.error {
      line-height: 1.1;
    }
  }

  .calendar__event {
    width: 100%;
    border: none;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    margin: 40px 0;

    a {
      background: #f4f4f4;
      padding: 20px;

      &:before,
      &:after {
        content: none;
      }
    }

    & p:before {
      content: none;
    }

    &-info {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      border: none;

      li {
        min-width: 50%;
        padding: 0 10px;
      }
    }
  }
}

.registration-event-page {
  .registration {
    &__club-data {
      justify-content: space-between;
      position: relative;
      border-bottom: 1px #bdbdbd solid;

      &:before {
        content: '';
        position: absolute;
        height: 90%;
        width: 1px;
        background: #bdbdbd;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      @media screen and (max-width: 767px) {
        &:before {
          content: none;
        }
      }

      label {
        width: calc((100% - 50px) / 2);
        max-width: none;
        margin: 0;
        @media screen and (max-width: 767px) {
          width: 100%;
          max-width: 500px;
          margin: 0 auto;
          &:first-child {
            margin-bottom: 15px;
          }
        }
      }

      .clubs-select {
        position: relative;
        width: 100%;

        #club {
        }

        .clubs-wrap {
          display: none;
          position: absolute;
          max-height: 305px;
          width: 100%;
          background-color: #fff;
          border: 1px solid #12426d;
          z-index: 10;
          overflow-y: scroll;

          .club {
            display: flex;
            align-items: center;
            padding: 5px;
            font-family: $jet-font400;
            font-size: 1.1rem;
            line-height: 1.3;
            color: #12426d;

            &:hover {
              background-color: #12426d;
              color: #fff;
            }
          }
        }

        // #club:focus + .clubs-wrap {
        //     display: block;
        // }
      }
    }

    .btn {
      max-width: 415px;
      width: 100%;
      @media screen and (max-width: 480px) {
        p {
          font-size: inherit;
        }
      }
    }
  }
}
