.slider {
    &__navigations {
        position: absolute;
        bottom: 120px;
        right: 5%;
        display: flex;
    }

    &__button-nav {
        width: 55px;
        height: 55px;
        z-index: 5;
        position: relative;
        margin: 0;

        &:hover {
            background: #FF8A00;
            border: none;
        }

        @media screen and (max-width: 1600px) {
            width: 44px;
            height: 44px;
        }
    }

    &__button-next {
        background: #12426D;
        top: -5px;
        right: 5px;

        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 7px solid #fff;
        }

        &_reverse {
            top: 0;
            right: 0;
        }
    }

    &__button-prev {
        border: 2px solid #fff;

        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-right: 7px solid #fff;
        }

        &_reverse {
            top: -5px;
            right: -5px;
        }
    }
}