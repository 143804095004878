//new
.user-page {
    min-height: 840px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    & .container {
        @media screen and (max-width: 1520px) {
            padding: 0 42px;
        }
        @media screen and (max-width: 767px) {
            padding: 0 15px;
        }

        &:nth-child(1) {
            max-width: 610px;
            width: 100%;
            background: url('../../img/user/bg_user-left.jpg');
            background-position: left top;
            background-repeat: no-repeat;
            background-size: cover;

            @media screen and (max-width: 1520px) {
                max-width: 494px;
            }
            @media screen and (max-width: 1024px) {
                max-width: 100%;
            }

            .bread-crumbs {
                & .container {
                    background: none;
                    padding: 0;
                }
            }
        }

        &:nth-child(2) {
            width: 100%;
            padding-top: 80px;
            background: url('../../img/registration/registration-bg.png') right top/cover no-repeat;
            padding-left: 0;
            position: relative;
            z-index: 1;
            @media screen and (max-width: 1024px) {
                background: none;
                padding-left: 42px;
            }
            @media screen and (max-width: 767px) {
                padding-top: 40px;
                padding-left: 15px;
            }
        }
    }

    .password-reset-notification {
        padding: 10px 15px;
        border-left: 6px solid #219653;
        margin: 25px 0;
        background: transparent;
        font-family: $jet-font400;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }

    .user {

        &__info {
            max-width: 410px;
            width: 100%;
            margin: 0 auto;

            &--main {
                display: flex;
                align-items: center;
                padding-bottom: 25px;
                border-bottom: 1px solid #e0e0e0;

                .photo {
                    position: relative;
                    min-width: 80px;
                    max-width: 80px;
                    width: 80px;
                    max-height: 80px;
                    height: 80px;
                    border-radius: 50%;
                    margin-right: 25px;
                    @media screen and (max-width: 767px) {
                        margin-right: 15px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 50%;
                    }

                    .change-photo {
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        z-index: -1;
                        opacity: 0;
                        background: rgba(255, 255, 255, 0.28);
                        transition: 0.5s;

                        &:hover {
                            cursor: pointer;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 20px;
                            height: 20px;
                        }

                        .new-photo {
                            display: none;
                        }
                    }

                    &:hover {
                        cursor: pointer;

                        .change-photo {
                            z-index: 2;
                            opacity: 1;
                            transition: 0.5s;
                        }
                    }
                }

                .name {
                    p {
                        display: flex;
                        margin-bottom: 5px;
                        font-family: $rubik-font500;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 120%;
                        color: #12426d;
                        @media screen and (max-width: 767px) {
                            font-size: 18px;
                        }
                    }

                    span {
                        font-family: $jet-font700;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 160%;
                        color: #828282;
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                }
            }

            &--additional {
                margin-top: 25px;

                ul {
                    li {
                        margin-bottom: 15px;

                        &:last-child {
                            margin: 0;
                        }

                        span {
                            font-family: $jet-font400;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 140%;
                            color: #828282;
                        }

                        p {
                            font-family: $jet-font500;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 140%;
                            color: #12426d;
                            @media screen and (max-width: 767px) {
                                font-size: 16px;
                            }
                        }
                    }
                }

                .payment {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 25px;
                    padding: 25px 0;
                    border-top: 1px solid #e0e0e0;
                    border-bottom: 1px solid #e0e0e0;

                    p {
                        margin-right: 10px;
                        font-family: $jet-font700;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 140%;
                        color: #12426d;
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 180px;
                        width: 100%;
                        height: 35px;
                        background: #219653;
                        font-family: $jet-font700;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 140%;
                        text-transform: uppercase;
                        color: #ffffff;
                        @media screen and (max-width: 767px) {
                            max-width: 150px;
                            font-size: 14px;
                        }
                    }
                }

                .logout {
                    &-wrapper {
                        padding: 25px 0;
                    }

                    &-link {
                        font-family: $jet-font500;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 140%;
                        color: #12426d;
                        text-decoration: none;
                        border: none;
                        background: transparent;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        &__content {
            max-width: 1210px;
            width: 100%;
            padding: 0 0 0 40px;
            margin-bottom: 50px;
            @media screen and (max-width: 1365px) {
                padding: 0 0 0 20px;
            }
            @media screen and (max-width: 1024px) {
                padding: 0;
            }

            .mod-info {
                display: flex;
                margin-top: 30px;
                flex-direction: column;
                padding: 115px;
                border: 3px solid #12426d;
                box-sizing: border-box;
                background-color: #fff;
                @media screen and (max-width: 1520px) {
                    padding: 50px 70px;
                    flex-direction: column;
                }
                @media screen and (max-width: 767px) {
                    padding: 20px;
                }

                h2 {
                    padding-bottom: 30px;
                    border-bottom: 2px solid #12426d;
                    @media screen and (max-width: 1520px) {
                        padding: 0 0 25px 0;
                        font-size: 35px;
                        line-height: 41px;
                        text-align: center;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: 20px;
                        line-height: 24px;
                        text-align: left;
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    //padding: 25px 0 0 0;
                    @media screen and (max-width: 1520px) {
                        align-items: center;
                    }
                    @media screen and (max-width: 767px) {
                        align-items: flex-start;
                    }

                    .title {
                        margin-bottom: 30px;
                        font-family: $jet-font700;
                        font-weight: 800;
                        font-size: 18px;
                        line-height: 22px;
                        color: #12426d;
                        @media screen and (max-width: 1520px) {
                            text-align: center;
                            margin-bottom: 15px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 20px;
                            line-height: 24px;
                            text-align: left;
                        }
                    }

                    p {
                        font-family: $jet-font400;
                        font-size: 16px;
                        line-height: 150%;
                        color: #12426d;
                        @media screen and (max-width: 1520px) {
                            text-align: center;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                            line-height: 20px;
                            text-align: left;
                        }
                    }
                }
            }

            .tabs {
                .tabs-nav-wrap {
                    z-index: 2;
                    @media screen and (max-width: 1024px) {
                        position: relative;
                        margin-bottom: 15px;
                        background: #f8f8f8;
                        box-shadow: inset 0px -1px 0px #12426d;
                    }

                    .mob-selector {
                        display: none;
                        @media screen and (max-width: 1024px) {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            min-height: 40px;
                        }

                        &--text {
                            // position: relative;
                            display: inline-block;
                            width: 100%;
                            border-right: 1px solid #12426d;

                            &::before {
                                // position: relative;
                                content: attr(data-text);
                                font-family: $jet-font400;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: #12426d;
                            }
                        }

                        &--arrow {
                            position: relative;
                            max-width: 37px;
                            width: 100%;
                            height: 40px;

                            span {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                display: block;
                                height: 5px;
                                border: 6px solid transparent;
                                border-right: none;
                                border-left: 8px solid #12426d;
                                transition: all 0.3s;
                                transform: translate(-50%, -50%) rotate(90deg);
                            }
                        }
                    }
                }

                &__nav {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-bottom: 30px;
                    border-bottom: 2px solid #12426d;
                    @media screen and (max-width: 1024px) {
                        margin-bottom: 20px;
                        display: none;
                        &.active {
                            position: absolute;
                            top: 100%;
                            display: block;
                            width: 100%;
                            padding-top: 15px;
                            background: #fff;
                            border: none;

                            li {
                                button {
                                    margin-bottom: 20px;
                                    font-family: $jet-font400;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: #12426d;
                                }
                            }
                        }
                    }

                    li {
                        padding-right: 5px;

                        &:last-child {
                            padding: 0;
                        }

                        button {
                            border: none;
                            background: transparent;
                            font-family: $jet-font400;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 22px;
                            color: #828282;
                            @media screen and (max-width: 1024px) {
                                font-size: 16px;
                                line-height: 20px;
                            }

                            &.active,
                            &:hover {
                                color: #ff8a00;
                                padding-bottom: 3px;
                                border-bottom: 2px solid #12426d;
                            }
                        }
                    }
                }

                &__content {
                    & .js-tabs-item {
                        display: none;

                        &.active {
                            display: block;
                        }

                        & .scroll {
                            height: calc(100vh - 250px);
                            padding-right: 30px;
                            overflow-y: scroll;
                            @media screen and (max-width: 1024px) {
                                height: 100%;
                                overflow-y: inherit;
                                padding: 0;
                            }

                            &::-webkit-scrollbar {
                                -webkit-appearance: none;
                                width: 3px;
                                background-color: transparent;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: #12426d;
                            }
                        }
                    }

                    &--competition {
                        .calendar {
                            &__content-list {
                                margin-top: 0;
                            }

                            &__event {
                                width: 100%;
                                flex: 0 1 100%;
                                @media screen and (max-width: 1365px) {
                                    a {
                                        padding: 20px;
                                    }
                                }
                                @media screen and (max-width: 1250px) {
                                    a {
                                        flex-direction: column;
                                        // flex-wrap: wrap;
                                    }
                                }
                            }

                            &__event-date {
                                @media screen and (max-width: 1365px) {
                                    margin-right: 30px;
                                }
                            }

                            &__event-info {
                                max-width: 645px;
                                width: 55%;
                                // width: 100%;
                                padding-right: 20px;
                                border-right: 1px solid #12426d;
                                @media screen and (max-width: 1520px) {
                                    width: 45%;
                                }
                                @media screen and (max-width: 1365px) {
                                    padding-left: 20px;
                                }
                                // @media screen and (max-width: 1250px) {
                                //     width: 65%;
                                //     border-right: none;
                                // }
                                @media screen and (max-width: 1250px) {
                                    width: 100%;
                                    padding-left: 0;
                                    margin: 15px 0 0;
                                    padding-top: 15px;
                                    padding-bottom: 15px;
                                    border: none;
                                    border-top: 1px solid #12426d;
                                    border-bottom: 1px solid #12426d;
                                }
                            }

                            &__event-status {
                                width: 28%;
                                height: 100%;
                                padding-left: 20px;
                                position: relative;
                                @media screen and (max-width: 1250px) {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    padding: 15px 0 0 0;
                                }
                                @media screen and (max-width: 767px) {
                                    flex-direction: column;
                                    align-items: flex-start;
                                }

                                span {
                                    color: #828282;
                                    font-family: $jet-font400;
                                    font-size: 16px;
                                    line-height: 20px;
                                    transition: 0.5s;
                                    @media screen and (max-width: 1250px) {
                                        margin-right: 10px;
                                    }
                                }

                                p {
                                    font-family: $rubik-font700;
                                    font-weight: 800;
                                    font-size: 20px;
                                    line-height: 24px;
                                    transition: 0.5s;

                                    &.REJECTED {
                                        color: red;
                                    }

                                    &.ACCEPTED {
                                        color: #219653;
                                    }

                                    &.PROCESSING {
                                        color: #ff8a00;
                                    }
                                }
                            }

                            @media screen and (min-width: 1024px) {
                                &__event:hover a > .calendar__event-status p,
                                &__event:hover a > .calendar__event-status span {
                                    transition: 0.5s;
                                    color: #fff;
                                }
                            }
                        }
                    }

                    &--info {
                        .card {
                            display: flex;
                            padding: 20px 0;
                            border-bottom: 1px solid #e0e0e0;
                            @media screen and (max-width: 767px) {
                                flex-direction: column;
                            }

                            p {
                                font-family: $jet-font400;
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 22px;
                                color: #12426d;
                                @media screen and (max-width: 767px) {
                                    font-size: 14px;
                                    line-height: 17px;
                                }
                            }

                            &--data {
                                font-family: $jet-font500 !important;
                                font-weight: 500 !important;
                                margin-right: 30px;
                                @media screen and (max-width: 767px) {
                                    margin: 0 0 15px;
                                }
                            }
                        }
                    }

                    &--form {
                        .registration {
                            margin: 0;
                            padding: 0;

                            label {
                                max-width: 350px;
                                margin: 25px 20px 0 0;
                                @media screen and (max-width: 1510px) {
                                    margin: 0 10px 0 0;
                                }
                                @media screen and (max-width: 1200px) {
                                    margin: 15px auto 0;
                                    max-width: 500px;
                                }
                            }

                            &__personal-data {
                                & > div {
                                    flex-wrap: wrap;
                                }
                            }

                            &__contact-data,
                            &__club-data {
                                flex-wrap: wrap;
                            }

                            &__weapon-data {
                                flex-wrap: wrap;

                                label {
                                    max-width: none;
                                }
                            }

                            & .btn {
                                margin: 0 auto 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

//old
// .user-page {
//     min-height: 840px;
//     & .container {
//         display: flex;
//         justify-content: space-between;
//         @media screen and (max-width: 1440px) {
//             padding: 0 42px;
//         }
//         @media screen and (max-width: 1024px) {
//             flex-direction: column;
//             align-items: center;
//         }
//     }
//     .user {
//         &__info {
//             max-width: 410px;
//             width: 100%;
//             margin-right: 30px;
//             @media screen and (max-width: 1024px) {
//                 margin-right: 0;
//             }
//             &--main {
//                 display: flex;
//                 align-items: center;
//                 padding-bottom: 25px;
//                 border-bottom: 1px solid #e0e0e0;
//                 .photo {
//                     position: relative;
//                     min-width: 80px;
//                     max-width: 80px;
//                     width: 80px;
//                     max-height: 80px;
//                     height: 80px;
//                     border-radius: 50%;
//                     margin-right: 25px;
//                     @media screen and (max-width: 767px) {
//                         margin-right: 15px;
//                     }
//                     img {
//                         width: 100%;
//                         height: 100%;
//                         object-fit: cover;
//                         object-position: center;
//                         border-radius: 50%;
//                     }
//                     .change-photo {
//                         position: absolute;
//                         left: 0;
//                         width: 100%;
//                         height: 100%;
//                         border-radius: 50%;
//                         z-index: -1;
//                         opacity: 0;
//                         background: rgba(255, 255, 255, 0.28);
//                         transition: 0.5s;
//                         &:hover {
//                             cursor: pointer;
//                         }
//                         img {
//                             position: absolute;
//                             top: 50%;
//                             left: 50%;
//                             transform: translate(-50%, -50%);
//                             width: 20px;
//                             height: 20px;
//                         }

//                         .new-photo {
//                             display: none;
//                         }
//                     }
//                     &:hover {
//                         cursor: pointer;
//                         .change-photo {
//                             z-index: 2;
//                             opacity: 1;
//                             transition: 0.5s;
//                         }
//                     }
//                 }

//                 .name {
//                     p {
//                         display: flex;
//                         margin-bottom: 5px;
//                         font-family: $rubik-font500;
//                         font-weight: 700;
//                         font-size: 20px;
//                         line-height: 120%;
//                         color: #12426d;
//                         @media screen and (max-width: 767px) {
//                             font-size: 18px;
//                         }
//                     }
//                     span {
//                         font-family: $jet-font700;
//                         font-weight: 700;
//                         font-size: 16px;
//                         line-height: 160%;
//                         color: #828282;
//                         @media screen and (max-width: 767px) {
//                             font-size: 14px;
//                         }
//                     }
//                 }
//             }
//             &--additional {
//                 margin-top: 25px;
//                 ul {
//                     li {
//                         margin-bottom: 15px;
//                         &:last-child {
//                             margin: 0;
//                         }
//                         span {
//                             font-family: $jet-font400;
//                             font-weight: 400;
//                             font-size: 14px;
//                             line-height: 140%;
//                             color: #828282;
//                         }
//                         p {
//                             font-family: $jet-font500;
//                             font-weight: 500;
//                             font-size: 18px;
//                             line-height: 140%;
//                             color: #12426d;
//                             @media screen and (max-width: 767px) {
//                                 font-size: 16px;
//                             }
//                         }
//                     }
//                 }

//                 .payment {
//                     display: flex;
//                     align-items: center;
//                     justify-content: space-between;
//                     margin-top: 25px;
//                     padding: 25px 0;
//                     border-top: 1px solid #e0e0e0;
//                     border-bottom: 1px solid #e0e0e0;
//                     p {
//                         margin-right: 10px;
//                         font-family: $jet-font700;
//                         font-weight: 700;
//                         font-size: 18px;
//                         line-height: 140%;
//                         color: #12426d;
//                         @media screen and (max-width: 767px) {
//                             font-size: 16px;
//                         }
//                     }
//                     span {
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;
//                         max-width: 120px;
//                         width: 100%;
//                         height: 35px;
//                         background: #219653;
//                         font-family: $jet-font700;
//                         font-weight: 700;
//                         font-size: 16px;
//                         line-height: 140%;
//                         text-transform: uppercase;
//                         color: #ffffff;
//                         @media screen and (max-width: 767px) {
//                             max-width: 100px;
//                             font-size: 14px;
//                         }
//                     }
//                 }
//             }
//         }
//         &__content {
//             max-width: 1210px;
//             width: 100%;
//             padding: 0 0 0 40px;
//             margin-bottom: 50px;
//             @media screen and (max-width: 1365px) {
//                 padding: 0 0 0 20px;
//             }
//             @media screen and (max-width: 1024px) {
//                 padding: 0;
//             }
//             .mod-info {
//                 display: flex;
//                 margin-top: 60px;
//                 padding: 115px;
//                 border: 3px solid #12426d;
//                 box-sizing: border-box;
//                 @media screen and (max-width: 1440px) {
//                     padding: 50px 70px;
//                     flex-direction: column;
//                 }
//                 @media screen and (max-width: 767px) {
//                     padding: 20px;
//                 }
//                 h2 {
//                     padding-right: 60px;
//                     border-right: 2px solid #12426d;
//                     @media screen and (max-width: 1440px) {
//                         padding: 0 0 25px 0;
//                         border-right: none;
//                         border-bottom: 2px solid #12426d;
//                         font-size: 35px;
//                         line-height: 41px;
//                         text-align: center;
//                     }
//                     @media screen and (max-width: 767px) {
//                         font-size: 20px;
//                         line-height: 24px;
//                         text-align: left;
//                     }
//                 }
//                 .info {
//                     display: flex;
//                     flex-direction: column;
//                     justify-content: center;
//                     padding-left: 60px;
//                     @media screen and (max-width: 1440px) {
//                         padding: 25px 0 0 0;
//                         align-items: center;
//                     }
//                     @media screen and (max-width: 767px) {
//                         align-items: flex-start;
//                     }
//                     .title {
//                         margin-bottom: 30px;
//                         font-family: $jet-font700;
//                         font-weight: 800;
//                         font-size: 18px;
//                         line-height: 22px;
//                         color: #12426d;
//                         @media screen and (max-width: 1440px) {
//                             text-align: center;
//                             margin-bottom: 15px;
//                         }
//                         @media screen and (max-width: 767px) {
//                             font-size: 20px;
//                             line-height: 24px;
//                             text-align: left;
//                         }
//                     }

//                     p {
//                         font-family: $jet-font400;
//                         font-size: 16px;
//                         line-height: 150%;
//                         color: #12426d;
//                         @media screen and (max-width: 1440px) {
//                             text-align: center;
//                         }
//                         @media screen and (max-width: 767px) {
//                             font-size: 16px;
//                             line-height: 20px;
//                             text-align: left;
//                         }
//                     }
//                 }
//             }
//             .tabs {
//                 .tabs-nav-wrap {
//                     z-index: 2;
//                     @media screen and (max-width: 1024px) {
//                         position: relative;
//                         margin-bottom: 15px;
//                         background: #f8f8f8;
//                         box-shadow: inset 0px -1px 0px #12426d;
//                     }
//                     .mob-selector {
//                         display: none;
//                         @media screen and (max-width: 1024px) {
//                             display: flex;
//                             align-items: center;
//                             justify-content: space-between;
//                             width: 100%;
//                             min-height: 40px;
//                         }
//                         &--text {
//                             // position: relative;
//                             display: inline-block;
//                             width: 100%;
//                             border-right: 1px solid #12426d;
//                             &::before {
//                                 // position: relative;
//                                 content: attr(data-text);
//                                 font-family: $jet-font400;
//                                 font-weight: 400;
//                                 font-size: 14px;
//                                 line-height: 17px;
//                                 color: #12426d;
//                             }
//                         }

//                         &--arrow {
//                             position: relative;
//                             max-width: 37px;
//                             width: 100%;
//                             height: 40px;

//                             span {
//                                 position: absolute;
//                                 top: 50%;
//                                 left: 50%;
//                                 display: block;
//                                 height: 5px;
//                                 border: 6px solid transparent;
//                                 border-right: none;
//                                 border-left: 8px solid #12426d;
//                                 transition: all 0.3s;
//                                 transform: translate(-50%, -50%) rotate(90deg);
//                             }
//                         }
//                     }
//                 }
//                 &__nav {
//                     display: flex;
//                     justify-content: space-between;
//                     flex-wrap: wrap;
//                     margin-bottom: 40px;
//                     border-bottom: 2px solid #12426d;
//                     @media screen and (max-width: 1024px) {
//                         margin-bottom: 20px;
//                         display: none;
//                         &.active {
//                             position: absolute;
//                             top: 100%;
//                             display: block;
//                             width: 100%;
//                             padding-top: 15px;
//                             background: #fff;
//                             border: none;
//                             li {
//                                 button {
//                                     margin-bottom: 20px;
//                                     font-family: $jet-font400;
//                                     font-weight: 400;
//                                     font-size: 14px;
//                                     line-height: 17px;
//                                     color: #12426d;
//                                 }
//                             }
//                         }
//                     }
//                     li {
//                         padding-right: 5px;
//                         &:last-child {
//                             padding: 0;
//                         }
//                         button {
//                             border: none;
//                             background: transparent;
//                             font-family: $jet-font400;
//                             font-weight: 400;
//                             font-size: 18px;
//                             line-height: 22px;
//                             color: #828282;
//                             @media screen and (max-width: 1024px) {
//                                 font-size: 16px;
//                                 line-height: 20px;
//                             }
//                             &.active,
//                             &:hover {
//                                 color: #ff8a00;
//                                 padding-bottom: 3px;
//                                 border-bottom: 2px solid #12426d;
//                             }
//                         }
//                     }
//                 }
//                 &__content {
//                     & .js-tabs-item {
//                         display: none;
//                         &.active {
//                             display: block;
//                         }

//                         & .scroll {
//                             height: calc(100vh - 250px);
//                             padding-right: 30px;
//                             overflow-y: scroll;
//                             @media screen and (max-width: 1024px) {
//                                 height: 100%;
//                                 overflow-y: inherit;
//                                 padding: 0;
//                             }
//                             &::-webkit-scrollbar {
//                                 -webkit-appearance: none;
//                                 width: 3px;
//                                 background-color: transparent;
//                             }

//                             &::-webkit-scrollbar-thumb {
//                                 background: #12426d;
//                             }
//                         }
//                     }
//                     &--competition {
//                         .calendar {
//                             &__content-list {
//                                 margin-top: 0;
//                             }
//                             &__event {
//                                 width: 100%;
//                                 @media screen and (max-width: 1365px) {
//                                     a {
//                                         padding: 20px;
//                                     }
//                                 }
//                                 @media screen and (max-width: 1250px) {
//                                     a {
//                                         flex-wrap: wrap;
//                                     }
//                                 }
//                             }
//                             &__event-date {
//                                 @media screen and (max-width: 1365px) {
//                                     margin-right: 30px;
//                                 }
//                             }
//                             &__event-info {
//                                 max-width: 645px;
//                                 width: 55%;
//                                 // width: 100%;
//                                 padding-right: 20px;
//                                 border-right: 1px solid #12426d;
//                                 @media screen and (max-width: 1440px) {
//                                     width: 45%;
//                                 }
//                                 @media screen and (max-width: 1365px) {
//                                     padding-left: 20px;
//                                 }
//                                 @media screen and (max-width: 1250px) {
//                                     width: 65%;
//                                     border-right: none;
//                                 }
//                                 @media screen and (max-width: 768px) {
//                                     width: 100%;
//                                     padding-left: 0;
//                                     margin: 15px 0 0;
//                                     padding-bottom: 15px;
//                                     border-bottom: 1px solid #12426d;
//                                 }
//                             }
//                             &__event-status {
//                                 width: 28%;
//                                 height: 100%;
//                                 padding-left: 20px;
//                                 @media screen and (max-width: 1250px) {
//                                     width: 100%;
//                                     display: flex;
//                                     align-items: center;
//                                     padding: 15px 0 0 0;
//                                 }
//                                 @media screen and (max-width: 768px) {
//                                     flex-direction: column;
//                                     align-items: flex-start;
//                                 }
//                                 span {
//                                     color: #828282;
//                                     font-family: $jet-font400;
//                                     font-size: 16px;
//                                     line-height: 20px;
//                                     transition: 0.5s;
//                                     @media screen and (max-width: 1250px) {
//                                         margin-right: 10px;
//                                     }
//                                 }
//                                 p {
//                                     font-family: $rubik-font700;
//                                     font-weight: 800;
//                                     font-size: 20px;
//                                     line-height: 24px;
//                                     transition: 0.5s;
//                                     &.registered {
//                                         color: #12426d;
//                                     }
//                                     &.paid {
//                                         color: #219653;
//                                     }
//                                     &.processing {
//                                         color: #ff8a00;
//                                     }
//                                 }
//                             }
//                             &__event:hover a > .calendar__event-status p,
//                             &__event:hover a > .calendar__event-status span {
//                                 transition: 0.5s;
//                                 color: #fff;
//                             }
//                         }
//                     }
//                     &--info {
//                         .card {
//                             display: flex;
//                             padding: 20px 0;
//                             border-bottom: 1px solid #e0e0e0;
//                             @media screen and (max-width: 767px) {
//                                 flex-direction: column;
//                             }
//                             p {
//                                 font-family: $jet-font400;
//                                 font-weight: 400;
//                                 font-size: 18px;
//                                 line-height: 22px;
//                                 color: #12426d;
//                                 @media screen and (max-width: 767px) {
//                                     font-size: 14px;
//                                     line-height: 17px;
//                                 }
//                             }
//                             &--data {
//                                 font-family: $jet-font500 !important;
//                                 font-weight: 500 !important;
//                                 margin-right: 30px;
//                                 @media screen and (max-width: 767px) {
//                                     margin: 0 0 15px;
//                                 }
//                             }
//                         }
//                     }
//                     &--form {
//                         .registration {
//                             margin: 0;
//                             padding: 0;
//                             label {
//                                 @media screen and (max-width: 1510px) {
//                                     margin: 0 10px 0 0;
//                                 }
//                                 @media screen and (max-width: 845px) {
//                                     margin: 15px auto 0;
//                                     max-width: 500px;
//                                 }
//                             }
//                             &__personal-data {
//                                 & > div {
//                                     flex-wrap: wrap;
//                                 }
//                             }
//                             &__contact-data,
//                             &__club-data {
//                                 flex-wrap: wrap;
//                             }
//                             &__weapon-data {
//                                 flex-wrap: wrap;
//                             }
//                             & .btn {
//                                 margin: 0 auto 20px;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
