.calendar-page {
    background: url('../../img/registration/registration-bg.png') center center/cover no-repeat;
}

.calendar {
    max-width: 1800px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 35px;
    position: relative;
    @media screen and (max-width: 767px) {
        padding: 0;
    }
    h2 {
        width: 80%;
    }
    &__years {
        position: absolute;
        right: 0;
        top: 0;
        width: 120px;
        transition: 0s;
        a {
            text-decoration: none;
            color: #12426D;
            border-bottom: 1px #12426D solid;
            font-family: $jet-font400;
            font-weight: 700;
            line-height: 25px;
            background: #F8F8F8;
            padding: 7px 15px;
            font-size: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            transition: .3s;
        }
        span {
            padding-left: 7px;
            position: relative;
            width: 25px;
            border-left: 1px solid #12426D;
            &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                width: 8px;
                height: 4px;
                transform: translateY(-50%);
                border: 6px solid transparent;
                border-bottom: none;
                border-top: 8px solid #12426d;
            }
        }
        &-list {
            position: relative;
            z-index: 4;
            display: none;
            transition: 0s;
            background: #fff;
            padding: 0 15px;
            li {
                font-size: 16px;
                line-height: 20px;
                font-family: $jet-font400;
                font-weight: 700;
                padding: 9px 0;
                color: #12426D;
                &:hover {
                    color: #FF8A00;
                    cursor: pointer;
                }
            }
        }
        &:hover {
            &>a {
                color: #FF8A00;
            }
        }
        @media screen and (max-width: 768px) {
            position: static;
            width: 100%;
            margin: 0;
        }
    }
    .tabs>div.active {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .subtabs__button-list {
        margin-top: 20px;
    }
    .trigger {
        max-width: 290px;
        width: 100%;
        height: 40px;
        background: #fff;
        border: 1px solid #12426D;
        display: none;
        p {
            text-transform: uppercase;
            font-family: $jet-font500;
            color: #12426D;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.3;
            &:before {
                content: '-';
                font-size: 14px;
            }
        }
        &.active {
            p {
                &:before {
                    content: '+';
                }
            }
        }
        @media screen and (max-width: 1024px) {
            display: block;
        }
    }
    &__content-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        @media screen and (max-width: 1024px) {
            // grid-template-columns: 1fr;
        }
        @media screen and (max-width: 767px) {
            margin-top: 20px;
        }
    }
    &__event {
        // width: calc((100% - 25px)/2);
        width: 100%;
        flex: 0 1 49%;
        border: 1px solid #12426D;
        position: relative;
        margin-bottom: 25px;
        transition: 1s;
        overflow: hidden;
        &>a {
            padding: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            background: #fff;
            text-decoration: none;
            &:after {
                content: "";
                position: absolute;
                height: 30%;
                width: 20%;
                top: 0;
                left: 0;
                z-index: 0;
                border-bottom: #fff0 252px solid;
                border-right: #fff0 62px solid;
                border-left: #F9F9F9 solid 141px;
                transition: 0.5s;
            }
            &:before {
                content: "";
                position: absolute;
                height: 30%;
                width: 20%;
                bottom: 0;
                right: 0;
                z-index: 0;
                border-top: #fff0 252px solid;
                border-left: #fff0 62px solid;
                border-right: #F9F9F9 solid 141px;
                transition: 0.5s;
            }
        }
        @media screen and (max-width: 1024px) {
            width: 100%;
            flex: 0 1 100%;
        }
        @media screen and (max-width: 767px) {
            a {
                padding: 25px;
                flex-direction: column;
            }
        }
        &.hidden {
            display: none;
            transition: 0.3s;
        }
    }
    &__bg {
        opacity: 0;
        position: absolute;
        transition: 0.3s;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(50%);
        }
        span {
            position: absolute;
            top: 5%;
            right: 5%;
            max-width: 56px;
            width: 100%;
            max-height: 56px;
            object-fit: contain;
            object-position: center;
            img {
                position: relative;
            }
        }
    }
    &__event-date {
        max-width: 160px;
        width: 100%;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        @media screen and (max-width: 767px) {
            margin-right: 0;
        }
        // div p:first-of-type {
        //     font-family: $rubik-font400;
        //     font-weight: 800;
        //     font-size: 2rem;
        //     line-height: 2.4rem;
        //     color: #12426D;
        //     white-space: nowrap;
        // }
        div p {
            font-family: $rubik-font400;
            font-weight: 800;
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: #12426D;
            text-transform: lowercase;
        }
        .results-text {
            font-family: $rubik-font500;
            color: #fff;
            font-size: 0.9rem;
            font-weight: 600;
            line-height: 1.3;
            text-transform: uppercase;
            margin-top: 0;
            padding-bottom: 4px;
            border-bottom: 2px solid #fff;
            opacity: 0;
        }
    }
    &__event-info {
        border-left: 1px solid #12426D;
        padding-left: 40px;
        position: relative;
        z-index: 1;
        li {
            margin-bottom: 20px;
            p:first-of-type {
                color: #828282;
                font-family: $jet-font400;
                font-size: 1rem;
                line-height: 1.2;
            }
            p:last-of-type,
            a {
                font-family: $rubik-font700;
                font-weight: 800;
                font-size: 1.2rem;
                line-height: 1.4rem;
                color: #12426D;
            }
            &:last-of-type,
            a {
                margin: 0;
            }
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-top: 20px;
            border-left: none;
            padding-left: 0;
            border-top: 1px solid #12426D;
            padding-top: 20px;
            li {
                p:first-of-type {
                    font-size: 0.9rem;
                }
                p:last-of-type,
                a {
                    font-size: 1.1rem;
                }
            }
        }
    }
    @media screen and (min-width: 1024px) {
        &__event:hover>a {
            color: #fff;
            background: none;
            &:after {
                content: "";
                position: absolute;
                height: 40%;
                width: 30%;
                opacity: 0.1;
                top: 0;
                right: 0;
                left: auto;
                z-index: 3;
                border-bottom: #fff0 280px solid;
                border-left: #fff0 62px solid;
                border-right: #fff solid 172px;
            }
            &:before {
                content: "";
                position: absolute;
                height: 40%;
                width: 30%;
                opacity: 0.1;
                bottom: 0;
                left: 0;
                right: auto;
                z-index: 3;
                border-top: #fff0 280px solid;
                border-right: #fff0 62px solid;
                border-left: #fff solid 172px;
            }
        }
        &__event:hover a>&__event-date .results-text {
            margin-top: 75px;
            opacity: 1;
            color: #fff;
        }
        &__event:hover a>&__event-date p,
        &__event:hover a>&__event-info li p,
        &__event:hover>&__event-info li a {
            transition: 0.5s;
            color: #fff;
        }
        &__event:hover a>&__bg {
            opacity: 1;
            transition: 0.5s;
        }
    }
}
