.results-page {
  z-index: 600;

  .results {
    // max-width: 1750px;
    // margin: 0 auto;
    & > .container {
      // padding: 20px 15px 80px;
      padding-top: 20px;
      padding-bottom: 80px;
      font-family: $jet-font400;
    }

    .calendar {
      padding: 0;
    }

    h2 {
      color: #12426d;
      text-align: left;
      font-weight: 800;
      font-size: 2.6rem;
      line-height: 3rem;
      @media screen and (max-width: 767px) {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }

    .tabs {
      margin: 20px 0;

      &-nav-wrap {
        position: relative;
      }

      &__nav {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 25px 0;
        border-bottom: 2px solid #12426d;

        @media screen and (max-width: 1024px) {
          margin-bottom: 20px;
          display: none;

          &.active {
            position: absolute;
            top: 100%;
            display: block;
            width: 100%;
            padding: 15px;
            background: #f8f8f8;
            border: none;
            margin-top: 0;
            z-index: 20;

            li {
              button {
                margin-bottom: 20px;
                font-family: $jet-font400;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #12426d;
              }
            }
          }
        }

        li {
          padding-right: 5px;

          &:last-child {
            padding: 0;
          }

          button {
            border: none;
            background: transparent;
            font-family: $jet-font400;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #12426d;
            transition: 0.5s;
            @media screen and (max-width: 1024px) {
              font-size: 16px;
              line-height: 20px;
            }

            &.active,
            &:hover {
              color: #ff8a00;
              padding-bottom: 3px;
              border-bottom: 2px solid #12426d;
              transform: 0.5s;
            }
          }
        }
      }

      .mob-selector {
        display: none;

        @media screen and (max-width: 1024px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          min-height: 40px;
          background: #f8f8f8;
          padding-left: 10px;
          border-bottom: 1px solid #12426d;
        }

        &--text {
          // position: relative;
          display: inline-block;
          width: 100%;
          border-right: 1px solid #12426d;

          &::before {
            // position: relative;
            content: attr(data-text);
            font-family: $jet-font400;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #12426d;
          }
        }

        &--arrow {
          position: relative;
          max-width: 37px;
          width: 100%;
          height: 40px;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            height: 5px;
            border: 6px solid transparent;
            border-right: none;
            border-left: 8px solid #12426d;
            transition: all 0.3s;
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
    }

    &__standart {
      width: 100%;

      .subtabs__button-list {
        border: none;

        li {
          margin-right: 10px;
          margin-bottom: 10px;

          button {
            margin-right: 0;
            padding: 6px 25px;
            border: 1px #e0e0e0 solid;
            background: transparent;
            color: #12426d;
            font-family: JetBrainsMono-Regular;
            transition: 0.3s;
            box-sizing: border-box;

            &.active,
            &:hover {
              background: #12426d;
              color: #fff;
              border: 1px solid #12426d;
            }
          }
        }
      }

      &--img {
        span {
          display: block;
          width: 100%;

          img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
          }
        }
      }
    }

    &__best {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .subtabs__button-list {
        border: none;
        margin-top: 10px;

        li {
          margin-right: 10px;
          margin-bottom: 10px;

          button {
            margin-right: 0;
            padding: 6px 25px;
            border: 1px #e0e0e0 solid;
            background: transparent;
            color: #12426d;
            font-family: JetBrainsMono-Regular;
            transition: 0.3s;
            box-sizing: border-box;

            &.active,
            &:hover {
              background: #12426d;
              color: #fff;
              border: 1px solid #12426d;
            }
          }
        }
      }

      .js-subtabs-item {
        width: 100%;
      }

      &_filter-download {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 40px;
        border-bottom: 1px #bdbdbd solid;
        width: 100%;

        & > p {
          max-width: 1020px;
          width: 100%;
          color: #12426d;
          line-height: 1.5;
        }

        & > a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 17px 20px;
          margin: 0 10px 0 auto;
          text-decoration: none;
          color: #fff;
          background: #12426d;
          // white-space: nowrap;
          p {
            padding: 0 20px 0 0;
            border-right: 1px #fff solid;
          }

          span {
            padding-left: 20px;
          }
        }

        & > span {
          height: 100%;
          width: 1px;
          background: #12426d;
          margin: 0 10px;
        }

        @media screen and (max-width: 1024px) {
          flex-direction: column;
          margin-top: -20px;
          & > p {
            order: 2;
            margin: 20px 0;
          }
          & > a {
            order: 3;
          }
        }
      }

      .calendar__years {
        position: relative;

        &-list {
          width: 100%;
          top: 100%;
          position: absolute;
        }

        @media screen and (max-width: 1024px) {
          width: 100%;
          position: static;
          &-list {
            position: static;
          }
        }
      }

      &_content {
        padding: 50px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        p {
          color: #12426d;
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 25px;
        }

        span,
        img {
          width: 100%;
        }
      }
    }

    &__list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .calendar__event {
        padding: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background: #fff;
        text-decoration: none;
        @media screen and (max-width: 767px) {
          a {
            padding: 0;
          }
        }

        .results-text {
          font-family: $rubik-font500;
          color: #12426d;
          font-size: 0.9rem;
          font-weight: 600;
          line-height: 1.3;
          text-transform: uppercase;
          margin-top: 75px;
          padding-bottom: 4px;
          border-bottom: 2px solid #12426d;
          opacity: 1;
          text-decoration: none;
          @media screen and (max-width: 767px) {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }

        @media screen and (min-width: 1024px) {
          &:hover > .calendar__event-date p,
          &:hover > .calendar__event-date a,
          &:hover > .calendar__event-info li p {
            transition: 0.5s;
            color: #fff;
            border-color: #fff;
          }

          &:hover > .calendar__bg {
            opacity: 1;
            transition: 0.5s;
          }
        }
        @media screen and (max-width: 767px) {
          padding: 25px;
          flex-direction: column;
        }
      }

      .calendar__event-info {
        li {
          a {
            // font-family: $rubik-font700;
            // font-weight: 800;
            // font-size: 1.2rem;
            // line-height: 1.4rem;
            // color: #12426d;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    &__filter {
      position: absolute;
      left: 50%;
      top: 100px;
      width: 100%;
      max-width: 1750px;
      display: flex;
      justify-content: flex-end;
      padding: 0 15px;
      transform: translateX(-50%);

      button {
        background: #f5f5f5;
        padding: 12px 20px;
        display: flex;
        border: none;
        outline: none;
        transition: 0.3s;

        &:hover {
          background: #12426d;

          p {
            color: #fff;
          }

          svg rect {
            fill: #fff;
          }
        }
      }

      p {
        font-family: $jet-font400;
        color: #12426d;
        font-size: 18px;
        line-height: 22px;
        padding-left: 10px;
        pointer-events: none;
      }

      @media screen and (max-width: 1024px) {
        position: fixed;
        top: auto;
        bottom: 20px;
        left: auto;
        right: 20px;
        z-index: 590;
        transform: none;
        button {
          border: 1px #12426d solid;
        }
      }

      &_list {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        right: 0;
        top: 93px;
        height: calc(100% - 93px);
        margin-right: -320px;
        transition: 0.3s;
        z-index: 600;
        max-width: 320px;
        width: 100%;
        background: #fff;
        border: 1px #12426d solid;
        overflow: scroll;
        @media screen and (max-width: 1024px) {
          top: 73px;
          height: calc(100% - 73px);
        }
        @media screen and (max-width: 416px) {
          top: 82px;
          height: calc(100% - 82px);
        }

        &.active {
          margin-right: 0;
        }

        .close {
          width: 100%;
          display: flex;
          padding: 20px 30px;
          justify-content: flex-end;
          background: #f7f7f7;

          button {
            width: 20px;
            height: 20px;
            position: relative;
            border: none;
            outline: none;

            span {
              position: absolute;
              height: 2px;
              width: 100%;
              top: 50%;
              left: 0;
              background: #12426d;
              pointer-events: none;

              &:nth-of-type(1) {
                transform: translateY(-50%) rotate(45deg);
              }

              &:nth-of-type(2) {
                transform: translateY(-50%) rotate(-45deg);
              }
            }
          }
        }

        &_years {
          padding: 23px 0;

          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 60px);
          margin: 0 auto;
        }

        .calendar__years {
          position: static;
        }

        form {
          width: calc(100% - 60px);
          margin: 0 auto;

          > div:not(.results__filter_list_years) {
            border-top: 1px #bdbdbd solid;
            padding: 22px 0;
          }

          label {
            margin-top: 15px;
            font-family: $jet-font400;
            font-size: 1rem;
            line-height: 1.2;
            display: flex;
            align-items: center;
            color: #12426d;
            position: relative;

            &:hover {
              cursor: pointer;
            }

            input {
              -webkit-appearance: checkbox;
              width: 25px;
              height: 25px;
              margin-right: 10px;
              padding: 5px;
              position: relative;
              z-index: 5;
              opacity: 0;

              &:hover {
                cursor: pointer;
              }
            }

            & span {
              position: absolute;
              width: 25px;
              height: 25px;
              border: 1px solid #12426d;
              left: 0;
              top: 0;
              // z-index: -1;

              &:before {
                content: url('../../img/federation/confirm.png');
                position: relative;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
              }
            }

            input:checked ~ span {
              background: #12426d;

              &:before {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &__absolute {
      display: flex;
      justify-content: space-between;
      padding: 17px 20px;
      margin: 30px 0 0;
      text-decoration: none;
      color: #fff;
      background: #12426d;
      white-space: nowrap;
      color: #fff;
      border: 1px #12426d solid;

      &:hover {
        color: #12426d;
        background: #fff;
      }
    }

    &__order {
      padding: 0 30px;
      max-width: 1200px;
      margin: 0 auto;
      width: 100%;
      @media screen and (max-width: 1024px) {
        padding: 20px 0 0;
      }

      .subtabs__button-list {
        border: none;

        li {
          margin-top: 0;
          margin-right: 10px;
          margin-bottom: 10px;

          button {
            margin-right: 0;
            padding: 6px 25px;
            border: 1px #e0e0e0 solid;
            background: transparent;
            color: #12426d;
            font-family: JetBrainsMono-Regular;
            transition: 0.3s;
            box-sizing: border-box;

            &.active,
            &:hover {
              background: #12426d;
              color: #fff;
              border: 1px solid #12426d;
            }
          }
        }
      }

      .js-subtabs-item {
        width: 100%;
      }

      .calendar__years {
        position: relative;
        margin-left: auto;

        &-list {
          width: 100%;
          top: 100%;
          position: absolute;
        }

        @media screen and (max-width: 1024px) {
          width: 100%;
          position: static;
          &-list {
            position: static;
          }
        }
      }

      .results__table {
        background: #fff;
        margin: 30px 0;

        .results__table_row {
          margin-top: 0;
          border-bottom: 1px solid #a7bed2;
        }

        .results__table_name {
          left: 0;
        }

        .results__table_points {
          flex-direction: column;
          min-width: 110px;

          a {
            display: block;
            width: 100%;

            &:hover {
              text-decoration: none;
            }

            &:nth-child(2) {
              margin-top: 10px;
            }
          }
        }
      }

      h4 {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #12426d;
        text-align: center;
      }

      p {
        font-size: 16px;
        line-height: 1.5;
        color: #12426d;

        span {
          text-decoration: underline;
          font-weight: bold;
        }
      }

      ul {
        li {
          color: #12426d;
          margin: 20px 0;
        }
      }
    }

    &__table {
      width: 100%;
      background: #a7bed2;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        background: transparent !important;
        opacity: 1 !important;
        height: 5px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #12426d !important;
        opacity: 1 !important;
        height: 5px;
        border-radius: 10px;
      }

      &_row {
        display: flex;
        background: #fff;
        margin-top: 1px;
        font-size: 16px;
        min-height: 60px;

        div {
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        div:nth-of-type(2n + 1) {
          background: #f5f5f5;
        }

        @media screen and (max-width: 767px) {
          font-size: 13px;
          width: calc(100% + 200px);
        }
      }

      &-short {
        overflow-x: scroll;

        ::-webkit-scrollbar {
          background: transparent !important;
          opacity: 1 !important;
          height: 5px;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: transparent !important;
          opacity: 1 !important;
          height: 5px;
        }

        .results__table_row {
          div,
          div:nth-of-type(2n + 1) {
            background: #fff;
          }

          @media screen and (max-width: 767px) {
            flex-wrap: wrap;
            height: auto;
            justify-content: space-between;
          }
        }
      }

      &_class,
      &_result {
        max-width: 135px;
        width: 100%;
        padding: 15px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 767px) {
          order: 3;
          width: 50%;
          max-width: none;
          justify-content: flex-end;
        }
      }

      &_class {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width: 767px) {
          order: 2;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            height: 80%;
            left: 100%;
            top: 50%;
            width: 1px;
            transform: translateY(-50%);
            background: #12426d;
          }
        }
      }

      &_fullname {
        width: 100%;
        padding: 15px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 767px) {
          order: 1;
          background: #12426d17 !important;
          border-bottom: 1px #fff solid;
        }
      }

      &_head {
        div,
        div:nth-of-type(2n + 1) {
          background: #12426d !important;
          color: #fff;
          text-transform: uppercase;
        }
      }

      &_category {
        min-width: 100%;
        text-align: center;
        background: #f5f5f5;
        padding: 15px 0;
        justify-content: center;
        position: sticky;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          margin: 0;
          color: #000;
        }

        span {
          color: #909090;
        }
      }

      &_number {
        min-width: 40px;
        max-width: 50px;
        width: 100%;
        padding: 15px 0;
        text-align: center;
        position: sticky;
        left: 0;
        @media screen and (max-width: 480px) {
          display: none !important;
        }
      }

      &_name {
        max-width: 895px;
        width: 100%;
        padding: 15px 0;
        padding-left: 10px;
        min-width: 260px;
        position: sticky;
        left: 40px;
        justify-content: flex-start !important;
        @media screen and (max-width: 767px) {
          min-width: 165px;
        }
        @media screen and (max-width: 480px) {
          left: 0;
        }
      }

      &_points {
        max-width: 155px;
        width: 100%;
        padding: 15px 0;
        text-align: center;
        min-width: 50px;
      }

      &_place {
        max-width: 155px;
        width: 100%;
        padding: 15px 0;
        text-align: center;
        min-width: 50px;
      }
    }
  }
}
