
@font-face {
    font-family: "JetBrainsMono-Light";
    src: url('../../fonts/JetBrainsMono-Light.woff2') format('woff2'),
        url('../../fonts/JetBrainsMono-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "JetBrainsMono-Medium";
    src: url('../../fonts/JetBrainsMono-Medium.woff2') format('woff2'),
        url('../../fonts/JetBrainsMono-Medium.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "JetBrainsMono-Regular";
    src: url('../../fonts/JetBrainsMono-Regular.woff2') format('woff2'),
        url('../../fonts/JetBrainsMono-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "JetBrainsMono-Bold";
    src: url('../../fonts/JetBrainsMono-Bold.woff2') format('woff2'),
        url('../../fonts/JetBrainsMono-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Rubik-Light";
    src: url('../../fonts/Rubik-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Rubik-Regular";
    src: url('../../fonts/Rubik-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Rubik-Medium";
    src: url('../../fonts/Rubik-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Rubik-Bold";
    src: url('../../fonts/Rubik-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

$jet-font300: JetBrainsMono-Light;
$jet-font400: JetBrainsMono-Regular;
$jet-font500: JetBrainsMono-Medium;
$jet-font700: JetBrainsMono-Bold;
$rubik-font300: Rubik-Light, sans-serif;
$rubik-font400: Rubik-Regular, sans-serif;
$rubik-font500: Rubik-Medium, sans-serif;
$rubik-font700: Rubik-Bold, sans-serif;
