main {
  position: relative;
  z-index: 2;
  min-height: calc(100vh - 451px);
}

h1 {
  font-family: $rubik-font700;
  color: #fff;
  font-size: 3rem;
  text-transform: uppercase;
  line-height: 3.7rem;
}

h2 {
  font-size: 2.7rem;
  font-family: $rubik-font700;
  font-weight: 800;
  line-height: 1.2;
  color: #12426d;
  text-transform: uppercase;
  @media screen and (max-width: 1370px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
}

.js-tabs-item {
  display: none;

  &.active {
    display: flex;
  }

  .js-subtabs-item {
    display: none;

    &.active {
      display: block;
    }
  }
}

.home-page {
  .tabs {
    margin: 0 0 20px;
  }

  .top-banner {
    position: relative;

    &__content {
      max-width: 1680px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      justify-content: space-between;
      align-items: center;
      bottom: 100px;
      padding: 100px 210px 0 100px;

      h1, & > p {
        padding: 10px;
        background-color: rgba( 0, 0, 0, 0.3);
      }

      span {
        width: 100%;
        height: 1px;
        border: 1px solid #ffffff;
        display: block;
        margin: 40px 0;
      }

      p {
        position: relative;  
        font-family: $jet-font400;
        color: #fff;
        font-size: 1rem;
        line-height: 1.3;
        max-width: 700px;
        letter-spacing: 1px;
      }

      a {
        margin: 20px 22px 0 12px;
      }
    }

    &__bg {
      max-height: 700px;
    }

    &__pagination {
      top: 8%;
      right: 5%;
      left: auto;
      width: max-content;
      font-size: 24px;
      font-family: $jet-font400;
      font-weight: 800;
      font-style: italic;
      color: #fff;
      max-height: 100px;
      position: absolute;
      z-index: 5;

      span:first-of-type {
        font-size: 96px;
      }
    }

    @media screen and (max-width: 1600px) {
      h1 {
        font-size: 2.4rem;
        line-height: 3rem;
      }
      &__content {
        padding: 100px 45px 0;

        span {
          margin: 20px 0;
        }
      }
      &__bg {
        img {
          height: 100%;
          width: 100%;
        }
      }
      &__navigations {
        left: 50px;
        bottom: 30px;
      }

      &__button-nav {
        width: 43px;
        height: 43px;
      }
      &__pagination {
        span:first-of-type {
          font-size: 70px;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      h1 {
        font-size: 2rem;
        line-height: 2.5rem;
        max-width: 910px;
      }
      p {
        max-width: 650px;
      }

      &__content a {
        margin: 20px 0 0 12px;
      }
    }
    @media screen and (max-width: 1024px) {
      &__content {
        bottom: 80px;
      }
      &__navigations {
        right: 50px;
        left: auto;
        bottom: 20px;
      }
    }
    @media screen and (max-width: 969px) {
      &__bg {
        img {
          height: 100%;
          max-height: 558px;
          width: auto;
        }
      }
    }
    @media screen and (max-width: 767px) {
      &__content {
        padding: 50px 25px 0;

        a {
          width: 255px;
          height: 44px;
        }
      }
      &__pagination {
        top: 20px;
        right: 20px;
        font-size: 20px;

        span:first-of-type {
          font-size: 60px;
        }
      }
      &__bg {
        img {
          height: 100%;
          max-height: 558px;
          width: auto;
        }
      }
    }
    @media screen and (max-width: 480px) {
      h1 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      &__content {
        padding: 50px 15px 10px;

        span {
          margin: 10px 0;
        }

        p {
          font-size: 0.9rem;
          line-height: 1.2;
        }

        a {
          width: 255px;
          margin-left: 30px;
          margin-top: 10px;
        }
      }
      &__navigations {
        bottom: 12px;
      }
    }
  }

  .news {
    padding: 60px 0 0 100px;
    @media screen and (max-width: 1600px) {
      padding: 60px 40px 0;
    }
    @media screen and (max-width: 480px) {
      padding: 40px 15px 0;
    }

    ul {
      list-style: none;
    }

    .tabs__button-list a {
      display: block;
      padding: 6px 25px;
      border: 1px #E0E0E0 solid;
      background: transparent;
      color: #12426D;
      font-family: $jet-font400;
      transition: 0.3s;
      box-sizing: border-box;
      text-decoration: none;

      &.active {
        background: #12426D;
        color: #fff;
        border: 1px solid #12426D;
      }

      @media screen and (min-width: 1024px) {
        &:hover {
          cursor: pointer;
          border: 1px solid #12426D;
        }
      }
    }

    &__content-list {
      margin: 20px 0 0;

      li {
        display: none;
      }

      li.active {
        display: block;
      }

      @media screen and (max-width: 767px) {
        margin: 15px 0 0;
      }
    }

    &__slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__slider-wrapper {
      margin-bottom: 30px;
    }

    &__slide {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 610px;
      height: auto;
      max-width: 475px;
      transition: 0.3s;

      a {
        display: block;
        height: 100%;
      }
    }

    &__bg {
      height: 100%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      span {
        position: absolute;
        right: 10%;
        top: 10%;
      }

      &:after {
        content: '';
        position: absolute;
        height: 40%;
        width: 30%;
        opacity: 0.1;
        top: 0;
        right: 0;
        z-index: 3;
        border-bottom: #fff0 280px solid;
        border-left: #fff0 62px solid;
        border-right: #fff solid 172px;
        transition: 0.6s;
      }

      &:before {
        content: '';
        position: absolute;
        height: 40%;
        width: 30%;
        opacity: 0.1;
        bottom: 0;
        left: 0;
        z-index: 3;
        border-top: #fff0 280px solid;
        border-right: #fff0 62px solid;
        border-left: #fff solid 172px;
        transition: 0.6s;
      }
    }

    &__slide:hover {
      & > a .news__bg:before {
        left: -100%;
      }

      & > a .news__bg:after {
        right: -100%;
      }
    }

    &__subslide {
      position: relative;
      height: 48%;

      .news__content {
        p:nth-of-type(2) {
          display: none;
        }
      }
    }

    &__subslide_gallery {
      padding-bottom: 10px;

      .news__subslide_bg {
        padding-top: 30px;
      }
    }

    &__subslide_video {
      padding-top: 20px;
    }

    &__subslide_bg {
      max-height: none;
      max-width: none;

      img:nth-of-type(1) {
        position: relative;
        z-index: 3;
      }

      img:nth-of-type(2) {
        position: absolute;
        z-index: 2;
        padding: 0 15px;
        top: 15px;
      }

      img:nth-of-type(3) {
        position: absolute;
        z-index: 1;
        padding: 0 30px;
      }

      &:after {
        content: '';
        position: absolute;
        height: 40%;
        width: 100%;
        opacity: 0.1;
        top: 0;
        right: 0;
        z-index: 3;
        border-bottom: #fff0 230px solid;
        border-left: #fff0 62px solid;
        border-right: #fff solid 150px;
        transition: 0.6s;
      }

      &:before {
        content: '';
        position: absolute;
        height: 40%;
        width: 100%;
        opacity: 0.1;
        bottom: 0;
        left: 0;
        z-index: 4;
        border-top: #fff0 230px solid;
        border-right: #fff0 62px solid;
        border-left: #fff solid 150px;
        transition: 0.6s;
      }
    }

    &__subslide:hover {
      & > a .news__bg:before {
        left: -100%;
      }

      & > a .news__bg:after {
        right: -100%;
      }
    }

    &__content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 40px;
      z-index: 5;

      @media screen and (max-width: 767px) {
        padding: 40px 20px;
      }

      p {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          background-color: #000;
          opacity: 0.3;
          z-index: -1;
        }
      }

      p:nth-of-type(1) {
        font-family: $rubik-font700, sans-serif;
        font-weight: 700;
        font-size: 1.7rem;
        line-height: 1.2;
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
          font-size: 1.2rem
        }
      }

      p:nth-of-type(2) {
        font-family: $jet-font400;
        color: #f2f2f2;
        line-height: 1.2;
      }
    }

    a {
      text-decoration: none;
      color: #fff;
    }

    &__slider-controls {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-right: 100px;
      @media screen and (max-width: 1200px) {
        padding-right: 16px;
      }
    }

    &__navigations {
      position: static;
    }

    &__button-prev {
      border-color: #c4c4c4;

      &:before {
        border-right-color: #c4c4c4;
      }
    }

    &__button-next {
      top: 0;
    }

    &__pagination {
      position: static;
      display: flex;
      flex-wrap: wrap;
      // width: max-content;
      margin-left: 10px;
      margin-top: 20px;
      transform: skewX(25deg);

      span {
        position: static;
        height: 15px;
        width: 15px;
        margin-bottom: 5px;
        transition: 0.3s;
        margin-left: 5px;
        border-radius: 0;
        background: transparent;
        border: 2px solid #b0b9c2;

        &.swiper-pagination-bullet-active {
          background: #12426d;
          border: none;
        }
      }

      @media screen and (max-width: 1024px) {
        margin-top: 0;
      }
    }
  }

  .calendar {
    padding: 30px 30px 40px;
  }

  .partners {
    padding: 0 0 60px 100px;
    position: relative;
    z-index: 10;
    @media screen and (max-width: 1600px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 30px;
    }

    &__slider {
      padding: 20px 0;
    }

    &__slide {
      border-right: 1px solid #bdbdbd;
      padding: 0 30px 0 0;
      // height: 180px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      // flex-wrap: wrap;
      flex-direction: column;
      max-width: 377px;

      p:nth-of-type(1) {
        font-family: $rubik-font500;
        font-size: 1.15rem;
        line-height: 1.3;
        color: #12426d;
        font-weight: 600;
        align-self: flex-start;
      }

      p:nth-of-type(2) {
        font-family: $jet-font400;
        font-size: 1rem;
        line-height: 1.2;
        color: #12426d;
      }

      a {
        font-family: Calibri, sans-serif;
        font-size: 1rem;
        line-height: 1.3rem;
        color: #ff8a00;
        align-self: flex-end;
        transition: 0.3s;

        &:hover {
          color: #12426d;
        }
      }

      @media screen and (max-width: 480px) {
        border: none;
        padding: 0;
      }
    }

    &__logo {
      width: 160px;
      height: 160px;
      margin: auto auto 10px;

      & > a {
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
        @media screen and (max-width: 767px) {
          object-fit: contain;
          object-position: center;
        }
      }
    }

    &__slider-controls {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-right: 100px;
      margin-top: 40px;
      @media screen and (max-width: 1024px) {
        padding-right: 10px;
      }
    }

    &__navigations {
      position: static;
    }

    &__button-prev {
      border-color: #c4c4c4;

      &:before {
        border-right-color: #c4c4c4;
      }
    }

    &__button-next {
      top: 0;
    }

    &__pagination {
      position: static;
      display: flex;
      flex-wrap: wrap;
      margin-left: 10px;
      // width: max-content;
      transform: skewX(25deg);

      span {
        position: static;
        height: 15px;
        width: 15px;

        transition: 0.3s;
        margin-bottom: 5px;
        margin-left: 5px;
        border-radius: 0;
        background: transparent;
        border: 2px solid #b0b9c2;

        &.swiper-pagination-bullet-active {
          background: #12426d;
          border: none;
        }
      }
    }
  }
}
