@import "_reset/fonts";
@import "_reset/_base";
@import "_parts/_section-bg";
@import "_parts/_calendar";
@import "_parts/_slider";
@import "_parts/_buttons";
@import "_parts/_bread-crumbs";
//bread-crumbs
@import "_parts/_navigation";
//navigation
@import "_parts/_tabs";
//navigation
@import "~swiper/swiper";
@import "~swiper/components/pagination/pagination";
@import '_layouts/header';
// ----------------------------------- Pages --------------------------------
@import '_layouts/home-page';
// home page
@import '_layouts/competition';
// competition page
@import '_layouts/registration';
// registration page
@import '_layouts/federation';
// federation page
@import '_layouts/clubsnfs';
// federation page
@import '_layouts/results';
// federation page
@import '_layouts/footer';
@import '_layouts/competition';
//competition
@import '_layouts/payment';
//payment
@import '_layouts/successful';
//successful
@import '_layouts/news';
//news
@import '_layouts/user';
//user
//lightbox
@import "~lightbox2/dist/css/lightbox.min.css";
//select2
@import "~select2/dist/css/select2.min.css";
.hidden:not(.location) {
    display: none;
    transition: .3s;
}
@import "~simplelightbox/dist/simple-lightbox.min.css";
