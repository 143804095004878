
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  transition: 0.3s;
}
.content_text-box{
    ol{
        display: block;
        list-style-type: decimal;
        padding-left:  40px;
    }
    li{
        list-style: inherit;
    }
    ul{
        display: block;
        list-style-type: decimal;
        padding-left:  40px;
    }
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block;
}

blockquote, q {
  quotes: none;
  &::before,
  &::after {
    content: '';
  }
}
html.active{
  overflow: hidden;
}

body.active{
  overflow: hidden !important;
  height: 100vh;
}
main{
  margin-top: 92px;
  @media screen and (max-width: 1024px){
    margin-top: 73px;
  }
  @media screen and (max-width: 416px){
    margin-top: 82px;
  }
}

table {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-width: 0;
}

td {
  margin: 0;
  padding: 0;
}

ul{
  list-style: none;
}

form {
  margin: 0;
  padding: 0;
}
input{
    -webkit-appearance: none;
}
input, textarea {
  border-radius: 0;
    &:focus{
        outline: none;

    }

}

input[type="button"], input[type="submit"], button {
  cursor: pointer;
}

input[type=submit], input[type=button], button {
  outline: none;

}


input::-ms-clear, input::-ms-reveal  { color: transparent; display: none; width : 0; height: 0; }

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration { display: none; }
input:focus::-ms-clear,
input:focus::-ms-reveal{  display: none; width : 0; height: 0; }
div, span {
  outline: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}input[type=number] {
     -moz-appearance:textfield; /* Firefox */
 }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
* {
  box-sizing: border-box;
    margin: 0 ;
    padding: 0;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

body, input{
    font: 14px "Gotham Pro", sans-serif ;
    color: #000;
}
html{
    overflow-x: hidden;
}
template{
    display: block;
}
body{
    &.overlay{
        height: 100vh;
        overflow: hidden;
        &:before{
            position: fixed;
            content:'';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.56);
            z-index: 101;
        }
    }
}
body, html{
    &.no-scroll{
        overflow: hidden;
        max-height: 100vh;
    }
}
