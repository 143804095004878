.btn {
    font-family: $rubik-font700,
        sans-serif;
    color: #fff;
    text-decoration: none;
    width: 273px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    position: relative;
    z-index: 2;
    cursor: pointer;

    &__diamond {
        transform: skewX(-25deg);
        transition: 0.3s;
        cursor: pointer;
        p {
            transform: skewX(25deg);
            position: relative;
            z-index: 2;
        }
        &:hover:after {
            top: -2px;
            left: -2px;
        }
        &-frame {
            &:after {
                position: absolute;
                content: '';
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                border: 2px #fff solid;
                top: 10px;
                left: -10px;
                z-index: -1;
                transition: 0.3s;
            }

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                background: #12426D;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }

        &_reverse {
            transform: skewX(25deg);
            transition: 0.3s;

            p {
                transform: skewX(-25deg);
                position: relative;
                z-index: 2;
            }

        }
    }
}