.container {
    padding: 0 100px;
    @media screen and (max-width: 1366px) {
        padding: 0 42px;
    }
    @media screen and (max-width: 767px) {
        padding: 0 15px;
    }
}

.competition-page {
    & .info {
        position: relative;
        padding: 0 0 80px;
        background: url('../../img/competition-page/bg_info.png');
        background-position: left top;
        background-repeat: no-repeat;
        @media screen and (max-width: 1024px) {
            padding: 0 0 10px;
        }

        h2 {
            padding-bottom: 30px;
            margin-bottom: 50px;
            border-bottom: 2px solid #12426d;
            @media screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
        }

        &__wrap {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 1024px) {
                flex-direction: column;
                align-items: center;
            }

            &--content {
                max-width: 443px;
                width: 100%;

                ul {
                    li {
                        display: flex;
                        padding: 25px 0;
                        border-bottom: 1px solid #e0e0e0;

                        &:first-child {
                            padding-top: 0;
                        }

                        &:nth-child(2) {
                            & div {
                                p {
                                    text-transform: lowercase;
                                }
                            }
                        }

                        .img-wrap {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            max-width: 35px;
                            width: 100%;
                            margin-right: 25px;

                            img {
                                width: auto;
                                height: auto;
                            }
                        }

                        & div {
                            span {
                                font-family: $jet-font500;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                color: #bdbdbd;
                            }

                            p {
                                font-family: $jet-font700;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 22px;
                                color: #12426d;
                            }
                        }
                    }
                }

                a {
                    margin-top: 30px;
                    @media screen and (max-width: 1024px) {
                        margin: 20px auto 0;
                    }

                    &::before {
                        transition: 0.5s;
                    }

                    &:hover {
                        cursor: pointer;

                        &::before {
                            background: #ff8a00;
                            transition: 0.5s;
                        }
                    }

                    p {
                        text-align: center;
                    }
                }

                &--info {
                    padding: 25px 0;
                    font-family: $jet-font700;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: #12426d;
                }
            }

            &--img {
                margin-left: 100px;
                width: 100%;

                @media screen and (max-width: 1200px) {
                    margin-left: 40px;
                }
                @media screen and (max-width: 1024px) {
                    margin-top: 30px;
                    margin-left: 0;
                }

                img {
                    max-width: 1139px;
                    width: 100%;
                    max-height: 451px;
                    height: 100%;
                    object-fit: cover;
                    object-position: left;
                    @media screen and (max-width: 1370px) {
                        max-width: 739px;
                    }
                    @media screen and (max-width: 1200px) {
                        display: block;
                        max-height: 320px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    & .tabs-wrap {
        margin-bottom: 60px;
        @media screen and (max-width: 1024px) {
            margin: 0 0 30px;
        }

        .tabs {
            .tabs-nav-wrap {
                z-index: 2;
                @media screen and (max-width: 1024px) {
                    position: relative;
                    margin-bottom: 15px;
                    background: #f8f8f8;
                    box-shadow: inset 0px -1px 0px #12426d;
                }

                .mob-selector {
                    display: none;
                    @media screen and (max-width: 1024px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        min-height: 40px;
                    }

                    &--text {
                        // position: relative;
                        display: inline-block;
                        width: 100%;
                        border-right: 1px solid #12426d;

                        &::before {
                            // position: relative;
                            content: attr(data-text);
                            font-family: $jet-font400;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: #12426d;
                        }
                    }

                    &--arrow {
                        position: relative;
                        max-width: 37px;
                        width: 100%;
                        height: 40px;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            display: block;
                            height: 5px;
                            border: 6px solid transparent;
                            border-right: none;
                            border-left: 8px solid #12426d;
                            transition: all 0.3s;
                            transform: translate(-50%, -50%) rotate(90deg);
                        }
                    }
                }
            }

            &__nav {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-bottom: 40px;
                border-bottom: 2px solid #12426d;
                @media screen and (max-width: 1024px) {
                    margin-bottom: 20px;
                    &.active {
                        top: 100%;
                        left: 0;
                        display: block;
                        width: 100%;
                        padding: 15px 15px 0;
                        // padding-top: 15px;
                        background: #fff;
                        border: none;

                        li {
                            button {
                                margin-bottom: 20px;
                                font-family: $jet-font400;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: #12426d;
                            }
                        }
                    }
                }

                li {
                    padding-right: 5px;

                    &:last-child {
                        padding: 0;
                    }

                    button {
                        border: none;
                        background: transparent;
                        font-family: $jet-font400;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                        color: #828282;
                        @media screen and (max-width: 1024px) {
                            font-size: 16px;
                            line-height: 20px;
                        }

                        &.active,
                        &:hover {
                            color: #ff8a00;
                            padding-bottom: 3px;
                            border-bottom: 2px solid #12426d;
                        }
                    }
                }
            }

            & .container {
                position: relative;
                z-index: 1;
            }

            &__content {
                & .js-tabs-item {
                    display: none;

                    &.active {
                        display: block;
                    }
                }

                &--text {
                    max-width: 1136px;
                    width: 100%;

                    p {
                        margin-bottom: 30px;
                        font-family: $jet-font400;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 160%;
                        color: #12426d;
                        @media screen and (max-width: 1024px) {
                            font-size: 16px;
                            margin-bottom: 20px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                            margin-bottom: 18px;
                        }

                        &:last-child {
                            margin: 0;
                        }
                    }
                }

                &--documents {
                    .document {
                        display: grid;
                        // grid-template-columns: repeat(auto-fill, minmax(290px, 500px));
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-gap: 25px;
                        @media screen and (max-width: 1370px) {
                            grid-gap: 20px;
                        }
                        @media screen and (max-width: 1024px) {
                            grid-template-columns: 1fr 1fr;
                        }
                        @media screen and (max-width: 767px) {
                            grid-template-columns: 1fr;
                            grid-gap: 15px;
                        }

                        a {
                            position: relative;
                            display: flex;
                            align-items: center;
                            max-width: 550px;
                            padding: 14px 14px 14px 0;
                            height: 100px;
                            border: 1px solid #12426d;
                            text-decoration: none;
                            @media screen and (max-width: 767px) {
                                padding: 12px 12px 12px 0;
                            }

                            .img-wrap {
                                position: relative;
                                z-index: 2;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                max-width: 120px;
                                width: 100%;
                                height: 100%;
                                margin-right: 25px;
                                border-right: 1px solid #12426d;
                                @media screen and (max-width: 1370px) {
                                    max-width: 88px;
                                    margin-right: 20px;
                                }
                                @media screen and (max-width: 767px) {
                                    max-width: 50px;
                                    margin-right: 13px;
                                }

                                img {
                                    width: 38px;
                                    height: 38px;
                                    @media screen and (max-width: 767px) {
                                        width: 30px;
                                        height: 30px;
                                    }
                                }
                            }

                            p {
                                position: relative;
                                z-index: 2;
                                font-family: $jet-font700;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 20px;
                                color: #12426d;
                                @media screen and (max-width: 767px) {
                                    font-size: 14px;
                                    line-height: 18px;
                                }
                            }

                            &::before {
                                content: url('../../img/competition-page/bg_doc-el-1.png');
                                position: absolute;
                                z-index: 1;
                                top: 0;
                                left: 0;
                                @media screen and (max-width: 767px) {
                                    display: none;
                                }
                            }

                            &:after {
                                content: url('../../img/competition-page/bg_doc-el-2.png');
                                position: absolute;
                                z-index: 1;
                                top: 0;
                                left: 50%;
                                @media screen and (max-width: 767px) {
                                    display: none;
                                }
                            }

                            &:hover {
                                p {
                                    color: #ff8a00;
                                }
                            }
                        }
                    }
                }

                &--img-table {
                    display: grid;
                    // grid-template-columns: repeat(auto-fill, minmax(290px, 550px));
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 25px;
                    @media screen and (max-width: 1370px) {
                        grid-gap: 20px;
                    }
                    @media screen and (max-width: 1024px) {
                        grid-template-columns: 1fr 1fr;
                    }
                    @media screen and (max-width: 767px) {
                        grid-template-columns: 1fr;
                        grid-gap: 15px;
                    }

                    img {
                        max-width: 557px;
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                        object-position: center;
                    }

                    .file {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        justify-content: flex-start;
                        border: 1px #12426d solid;
                        padding: 14px 30px 14px 40px;
                        width: 100%;
                        height: 100px;
                        background: #fff;

                        @media screen and (max-width: 1600px) {
                            padding: 10px 20px;
                        }

                        @media screen and (max-width: 767px) {
                            padding: 10px;
                            margin-right: 0;
                            margin-bottom: 15px;
                            height: 78px;
                        }

                        span {
                            padding-right: 40px;
                            display: flex;
                            align-items: center;
                            height: 100%;
                            border-right: 1px #12426d solid;

                            @media screen and (max-width: 1600px) {
                                padding-right: 25px;
                            }

                            @media screen and (max-width: 767px) {
                                padding-right: 15px;
                            }

                            @media screen and (max-width: 767px) {
                                img {
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }

                        p {
                            color: #12426d;
                            padding-left: 25px;
                            font-family: $jet-font400;
                            font-weight: 700;
                            font-size: 1rem;
                            line-height: 1.2;

                            @media screen and (max-width: 1200px) {
                                font-size: 0.9rem;
                                line-height: 1.1;
                            }

                            @media screen and (max-width: 767px) {
                                font-size: 0.8rem;
                                line-height: 1;
                                padding-left: 15px;
                            }
                        }
                    }
                }

                &--table {
                    ul {
                        display: flex;
                        border-bottom: 1px solid #a7bed2;
                        padding: 5px 0;
                        @media screen and (max-width: 767px) {
                            padding: 0;
                        }

                        &:first-child {
                            border: none;
                        }

                        li {
                            display: flex;
                            align-items: center;
                            // width: calc(100% / 4);
                            flex: 1 1 25%;
                            min-height: 50px;
                            padding-right: 10px;
                            text-align: left;
                            font-family: $jet-font500;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            @media screen and (max-width: 1024px) {
                                font-size: 12px;
                                line-height: 16px;
                            }

                            &:first-child {
                                padding-left: 10px;
                                @media screen and (max-width: 1024px) {
                                    padding-left: 10px;
                                }
                            }
                        }
                    }

                    .title {
                        background: #12426d;

                        li {
                            text-transform: uppercase;
                            color: #fff;

                            &:nth-child(1) {
                                // width: 5%;
                                flex: 0 0 5%;
                            }

                            @media screen and (max-width: 767px) {
                                &:nth-child(1) {
                                    // width: 5%;
                                }
                                &:nth-child(2) {
                                    // width: 75%;
                                    // width: 70%;
                                    flex: 1 1 95%;
                                    padding-left: 10px;
                                    text-align: left;
                                    justify-content: flex-start;
                                }
                                &:nth-child(3) {
                                    // width: 20%;
                                    // flex: 1 1 100%;
                                    // text-align: right;
                                    // justify-content: flex-end;
                                    display: none;
                                }
                                &:nth-child(4) {
                                    display: none;
                                    flex: 1 1 100%;
                                }
                                &:nth-child(5) {
                                    display: none;
                                    flex: 1 1 100%;
                                }
                            }
                        }
                    }

                    .people {
                        @media screen and (max-width: 767px) {
                            flex-wrap: wrap;
                        }

                        li {
                            &:nth-child(1) {
                                // width: 5%;
                                flex: 0 0 5%;
                                padding-right: 10px;
                            }

                            color: #000;
                            @media screen and (max-width: 767px) {
                                width: 100%;
                                padding-left: 10px;
                                border-bottom: 1px solid #a7bed2;
                                &:nth-child(1) {
                                    // width: 5%;
                                }
                                &:nth-child(2) {
                                    // width: 75%;
                                    // width: 70%;
                                    flex: 1 1 90%;
                                    position: relative;
                                    // &::before {
                                    //     content: "";
                                    //     position: absolute;
                                    //     right: 5px;
                                    //     top: 50%;
                                    //     transform: translateY(-50%);
                                    //     display: block;
                                    //     height: 60%;
                                    //     border-right: 1px solid #a7bed2;
                                    // }
                                }
                                &:nth-child(3) {
                                    // width: 20%;
                                    // text-align: right;
                                    // justify-content: flex-end;
                                    flex: 1 1 100%;
                                }
                                &:nth-child(3),
                                &:nth-child(4),
                                &:nth-child(5) {
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: flex-start;
                                    flex: 1 1 100%;

                                    &::before {
                                        content: attr(data-title);
                                        font-family: $jet-font500;
                                        font-weight: 500;
                                        font-size: 12px;
                                        line-height: 15px;
                                        text-transform: uppercase;
                                        color: #12426d;
                                    }
                                }
                            }
                        }
                    }
                }

                &--gallery {
                    .gallery__wrap {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-gap: 25px;
                        @media screen and (max-width: 1370px) {
                            grid-gap: 19px;
                        }
                        @media screen and (max-width: 1024px) {
                            grid-template-columns: 1fr 1fr;
                        }

                        & .content-wrap {
                            position: relative;
                            max-width: 411px;
                            max-height: 280px;
                            transition: 1s;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }

                            &.video {
                                height: 100%;

                                .iframe-wrapper,
                                .video-wrapper {
                                    position: relative;
                                    padding-bottom: 68.25%;
                                    height: 0;
                                    width: 100%;

                                    & iframe,
                                    & video {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }

                            &.img {
                                padding-bottom: 68.25%;
                                height: 0;
                                width: 100%;
                                overflow: hidden;

                                img {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }

                                &:before {
                                    content: '';
                                    position: absolute;
                                    height: 30%;
                                    width: 20%;
                                    bottom: 0;
                                    right: 0;
                                    opacity: 0.1;
                                    z-index: -1;
                                    border-top: #fff0 230px solid;
                                    border-left: #fff0 62px solid;
                                    border-right: #f9f9f9 solid 140px;
                                    transition: 0.5s;
                                }

                                &:after {
                                    content: '';
                                    position: absolute;
                                    height: 30%;
                                    width: 20%;
                                    top: 0;
                                    left: 0;
                                    opacity: 0.1;
                                    z-index: -1;
                                    border-bottom: #fff0 230px solid;
                                    border-right: #fff0 62px solid;
                                    border-left: #f9f9f9 solid 140px;
                                    transition: 0.5s;
                                }

                                .hover__bg {
                                    opacity: 0;
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    transition: 0.3s;
                                    background-color: rgba(18, 66, 109, 0.7);

                                    & img {
                                        width: 42px;
                                        height: 42px;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    }
                                }

                                @media screen and (min-width: 1024px) {
                                    &:hover {
                                        &:before {
                                            content: '';
                                            position: absolute;
                                            height: 40%;
                                            width: 30%;
                                            opacity: 0.1;
                                            bottom: 0;
                                            left: 0;
                                            right: auto;
                                            z-index: 3;
                                            border-top: #fff0 230px solid;
                                            border-right: #fff0 62px solid;
                                            border-left: #fff solid 140px;
                                        }

                                        &:after {
                                            content: '';
                                            position: absolute;
                                            height: 40%;
                                            width: 30%;
                                            opacity: 0.1;
                                            top: 0;
                                            right: 0;
                                            left: auto;
                                            z-index: 3;
                                            border-bottom: #fff0 230px solid;
                                            border-left: #fff0 62px solid;
                                            border-right: #fff solid 140px;
                                        }

                                        .hover__bg {
                                            opacity: 1;
                                            transition: 0.5s;
                                            z-index: 2;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .gallery__nav {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 50px;
                        @media screen and (max-width: 767px) {
                            margin-top: 35px;
                        }

                        &--pagination {
                            display: flex;
                            position: relative;
                            flex-wrap: wrap;
                            justify-content: center;

                            & span {
                                &.swiper-pagination-bullet {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 49px;
                                    height: 49px;
                                    font-family: $jet-font500 !important;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: #828282;
                                    background: #fff;
                                    border-radius: inherit;
                                    opacity: 1;
                                    @media screen and (max-width: 767px) {
                                        width: 30px;
                                        height: 30px;
                                        font-size: 14px;
                                        line-height: 18px;
                                    }

                                    &.swiper-pagination-bullet-active {
                                        background: #12426d;
                                        color: #fff;
                                    }

                                    &:hover {
                                        background: #ff8a00;
                                        color: #fff;
                                    }
                                }
                            }
                        }

                        &--control {
                            position: relative;
                            display: flex;
                            margin-left: 10px;

                            &--prev {
                                border-color: #c4c4c4;

                                &:before {
                                    border-right-color: #c4c4c4;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
