.successful-page {
  background: url('../../img/registration/registration-bg.png') center center/cover no-repeat;

  .title {
    margin-bottom: 20px;
    font-family: $rubik-font700;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    text-transform: uppercase;
    color: #219653;
    text-align: center;
    @media screen and (max-width: 1024px) {
      font-size: 32px;
      line-height: 40px;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .sub-title {
    margin-bottom: 20px;
    font-family: $rubik-font700;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase;
    color: #12426d;
    text-align: center;
    @media screen and (max-width: 1024px) {
      font-size: 18px;
      line-height: 22px;
    }
    @media screen and (max-width: 767px) {
      font-size: 17px;
      line-height: 21px;
    }
  }

  &__info {
    margin: 0 0 50px;
    @media screen and (max-width: 767px) {
      margin: 0 0 30px;
    }

    & .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .text {
      margin-bottom: 57px;
      font-family: $jet-font400;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: #12426d;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
        font-size: 14px;
      }
    }

    .info-document {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 850px;
      width: 100%;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
      }

      &__cards {
        display: flex;
        align-items: center;
        max-width: 410px;
        width: 100%;
        padding: 14px 14px 14px 0;
        margin-bottom: 15px;
        min-height: 100px;
        border: 1px solid #12426d;
        text-decoration: none;
        @media screen and (max-width: 1024px) {
          margin-bottom: 15px;
          &:last-child {
            margin: 0;
          }
        }
        @media screen and (max-width: 767px) {
          padding: 12px 12px 12px 0;
        }

        &--img-wrap {
          flex: 0 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 120px;
          width: 100%;
          height: 100%;
          margin-right: 25px;
          border-right: 1px solid #12426d;
          @media screen and (max-width: 1370px) {
            max-width: 88px;
            margin-right: 20px;
          }
          @media screen and (max-width: 767px) {
            max-width: 50px;
            margin-right: 13px;
          }

          img {
            width: 38px;
            height: 38px;
            @media screen and (max-width: 767px) {
              width: 30px;
              height: 30px;
            }
          }
        }

        &--text {
          flex: 0 1 250px;
          display: flex;
          flex-direction: column;

          p {
            font-family: $jet-font700;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #12426d;
            word-break: break-all;
            @media screen and (max-width: 767px) {
              font-size: 13px;
              line-height: 18px;
            }
          }

          a {
            font-family: $jet-font700;
            font-size: 16px;
            line-height: 20px;
            color: #ff8a00;

            &:hover {
              color: #12426d;
            }

            @media screen and (max-width: 767px) {
              font-size: 13px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  &__recommendation {
    padding-bottom: 80px;

    .sub-title {
      margin-bottom: 30px;
      text-align: center;
    }

    .calendar {
      padding: 60px 100px;
      @media screen and (max-width: 1600px) {
        padding: 60px 40px;
      }
      @media screen and (max-width: 480px) {
        padding: 40px 15px;
      }

      &__event {
        @media screen and (max-width: 767px) {
          margin-bottom: 15px;
        }

        .reg {
          display: flex;
          justify-content: flex-end;
          height: 40px;
          padding: 10px 15px;
          border-top: 1px solid #12426d;
          position: relative;
          @media screen and (max-width: 767px) {
            justify-content: center;
            margin: 0 20px;
          }

          a {
            width: max-content;
            padding: 0;
            font-family: $rubik-font700;
            font-weight: 700;
            font-size: 14px;
            line-height: 130%;
            text-transform: uppercase;
            color: #12426d;
          }
        }
      }

      &__event-date {
        @media screen and (max-width: 767px) {
          padding-bottom: 10px;
        }
      }

      &__event:hover .reg a {
        transition: 0.5s;
        color: #fff;
      }
    }
  }
}
