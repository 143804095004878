.bread-crumbs {
    padding: 21px 0 42px;
    ul {
        display: flex;
        flex-wrap: wrap;
        li {
            position: relative;
            margin-right: 10px;
            padding-right: 20px;
            &:before {
                content: ">";
                position: absolute;
                bottom: 0;
                right: 0;
                font-family: $jet-font400;
                font-size: 14px;
                line-height: 17px;
                color: #828282;
                text-decoration: none;
            }
            &:last-child {
                margin: 0;
                padding: 0;
                &::before {
                    display: none;
                }
            }
            a,
            p {
                font-family: $jet-font400;
                font-size: 14px;
                line-height: 17px;
                color: #828282;
                text-decoration: none;
            }
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
