
.payment {
  padding-bottom: 80px;

  & .container {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__info {
    max-width: 557px;
    width: 100%;
    margin-right: 55px;
    @media screen and (max-width: 1370px) {
      max-width: 430px;
    }
    @media screen and (max-width: 1200px) {
      margin-bottom: 20px;
      margin-right: 0;
    }

    h2 {
      margin-bottom: 40px;
      @media screen and (max-width: 767px) {
        margin-bottom: 25px;
      }
    }

    &--card {
      max-width: 557px;
      width: 100%;
      @media screen and (max-width: 1370px) {
        max-width: 415px;
      }

      &--wrap {
        padding: 50px;
        border: 1px solid #bdbdbd;
        border-bottom: none;
        @media screen and (max-width: 1370px) {
          padding: 30px;
        }
        @media screen and (max-width: 767px) {
          padding: 25px;
        }
      }

      .title {
        margin-bottom: 30px;
        font-family: $rubik-font700;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        color: #12426d;
        @media screen and (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      ul {
        li {
          display: flex;
          padding-top: 25px;

          &:first-child {
            padding-top: 0;
          }

          @media screen and (max-width: 767px) {
            padding-top: 15px;
          }

          .img-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 35px;
            width: 100%;
            margin-right: 25px;
            @media screen and (max-width: 767px) {
              max-width: 30px;
              margin-right: 20px;
            }

            img {
              width: auto;
              height: auto;
            }
          }

          & div {
            span {
              font-family: $jet-font500;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #bdbdbd;
            }

            p {
              font-family: $jet-font700;
              font-weight: 700;
              font-size: 18px;
              line-height: 22px;
              color: #12426d;
              @media screen and (max-width: 767px) {
                font-size: 15px;
                line-height: 20px;
              }
            }
          }
        }
      }

      .price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 5px 50px;
        background: #12426d;
        @media screen and (max-width: 1370px) {
          padding: 5px 30px;
        }
        @media screen and (max-width: 767px) {
          padding: 5px 20px;
        }

        span {
          font-family: $jet-font700;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #ffffff;
          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }


      a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $jet-font700;
        text-decoration: none;
        text-align: center;
        color: #fff;
        padding: 5px 50px;
        margin-top: 15px;
        background: #12426d;
        height: 60px;
        vertical-align: middle;
        font-size: 18px;
        line-height: 50px;

        &:hover {
          background-color: #0b273f;
        }

        @media screen and (max-width: 1370px) {
          padding: 5px 30px;
        }
        @media screen and (max-width: 767px) {
          padding: 5px 20px;
          font-size: 16px;
          line-height: 20px;
        }

      }
    }

    &--text {
      max-width: 557px;
      width: 100%;
      margin-top: 60px;
      @media screen and (max-width: 1370px) {
        max-width: 410px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }

      p {
        font-family: $jet-font400;
        font-weight: 400;
        font-size: 18px;
        line-height: 160%;
        color: #12426d;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 160%;
        }
      }
    }
  }

  &__api {
    width: 100%;

    iframe {
      max-width: 1045px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

