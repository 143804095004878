.footer {
  background: rgb(45, 67, 90);
  padding: 75px 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media screen and (max-width: 1600px) {
    padding: 80px 40px;
  }
  @media screen and (max-width: 1024px) {
    padding: 40px 30px;
  }
  @media screen and (max-width: 600px) {
    padding: 10px 15px;
  }

  &:before {
    content: "";
    position: absolute;
    height: 40%;
    width: 30%;
    top: 0;
    left: 0;
    z-index: 3;
    border-bottom: #fff0 1200px solid;
    border-right: #fff0 62px solid;
    border-left: #344A5F solid 600px;
  }

  &:after {
    content: "";
    position: absolute;
    height: 40%;
    width: 30%;
    bottom: 0;
    right: 0;
    z-index: 3;
    border-top: #fff0 1100px solid;
    border-left: #fff0 62px solid;
    border-right: rgb(36, 61, 83) solid 550px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 208px;
    position: relative;
    z-index: 4;

  }

  & * {
    color: #fff;
  }

  .logo {
    padding: 0;
    max-width: none;

    img {
      max-width: 63px;
    }

    p {
      color: #fff;
    }
  }

  &__logo-box {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0 20px;

    & > p {
      padding-bottom: 20px;
      font-family: $rubik-font400;
      line-height: 17px;
      opacity: 0.8;
      letter-spacing: 1px;
    }
  }

  &__copyright {
    p, a {
      font-size: 12px;
      color: #B0B9C2;
      letter-spacing: 1px;
      line-height: 15px;
    }

    a:hover {
      color: #fff;
    }
  }

  &__sitemap {
    max-width: 266px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 20px;

    a {
      text-decoration: none;
      font-family: $rubik-font400;
      font-size: 1rem;
      line-height: 1.2;
      opacity: 0.8;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 0;
        bottom: -2px;
        height: 1px;
        background: #fff;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.3s;
      }

      &:hover:before {
        width: 100%;
      }
    }
  }

  &__contacts {
    max-width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 20px;

    & > a,
    & > p {
      text-decoration: none;
      font-family: $rubik-font400;
      font-size: 1rem;
      line-height: 1.2;
      opacity: 0.8;
      padding-left: 40px;
      position: relative;

      &:before {
        left: 0;
        top: 0;
        width: 20px;
        height: auto;
        position: absolute;
      }

      &:nth-child(1) {
        &:before {
          content: url('../../img/footer/phone.png');
        }
      }

      &:nth-child(2) {
        &:before {
          content: url('../../img/footer/map.png');
        }
      }

      &:nth-child(3) {
        &:before {
          content: url('../../img/footer/mail.png');
        }
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        transform: scale(1.1);
      }
    }

  }

  &__social {
    margin-top: 20px;

    a {
      margin-right: 10px;
      display: inline-block;

      &:last-of-type {
        margin: 0;
      }
    }

    a:hover {
      transform: scale(1.1);
    }
  }

  &__document-box {
    max-width: 240px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 20px;

    img {
      max-width: 186px;
    }

    a {
      &:hover {
        transform: scale(0.9);
      }
    }
  }

  &__document-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    a {
      margin: 7px 0;
      font-family: $rubik-font400;
      font-size: 0.9rem;
      line-height: 1;
      opacity: 0.8;
      text-align: right;
    }

    a:hover {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 1024px) {
    &__wrapper {
      flex-wrap: wrap;
      height: auto;
    }
    &__logo-box,
    &__sitemap,
    &__contacts,
    &__document-box {
      width: calc(50% - 20px);
      max-width: none;
      margin: 20px 0;
    }

    &__logo-box {
      .logo {
        margin-bottom: 10px;
      }
    }
    &__copyright {
      margin-bottom: 10px;
    }
    &__sitemap {
      align-items: flex-end;

      li {
        margin: 5px 0;
        text-align: end;
      }
    }
    &__contacts {
      a, p {
        margin: 10px 0;
      }

      a {
        margin-right: 10px;
      }
    }

  }
  @media screen and (max-width: 600px) {
    &:before {
      content: "";
      position: absolute;
      height: 25%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 3;
      border: none;
      background: #344A5F;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
    }

    &:after {
      content: "";
      position: absolute;
      height: 25%;
      width: 100%;
      bottom: 0;
      right: 0;
      z-index: 3;
      border: none;
      background: rgb(36, 61, 83);
      clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%);

    }
    &__logo-box,
    &__sitemap,
    &__contacts,
    &__document-box {
      width: 100%;
    }
    &__sitemap {
      align-items: flex-start;

      li {
        text-align: left;
      }
    }
    &__document-box,
    &__document-list {
      align-items: flex-start;
    }

  }
}

.popup {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: rgba(18, 66, 109, 0.45);
  z-index: 1111;
  overflow-y: scroll;

  &__sign-in, &__reset-password {
    display: none;

    &.active {
      display: flex;
    }
  }

  &__wrapper {
    max-width: 553px;
    width: 100%;
    background: #fff;
    padding: 75px;
    position: absolute;
    top: 50px;

    &:after {
      content: "";
      position: absolute;
      height: 30%;
      width: 20%;
      top: 0;
      left: 0;
      z-index: 0;
      border-bottom: #fff0 252px solid;
      border-right: #fff0 62px solid;
      border-left: #F9F9F9 solid 141px;
      transition: 0.5s;
    }

    &:before {
      content: "";
      position: absolute;
      height: 30%;
      width: 20%;
      bottom: 0;
      right: 0;
      z-index: 0;
      border-top: #fff0 252px solid;
      border-left: #fff0 62px solid;
      border-right: #F9F9F9 solid 141px;
      transition: 0.5s;
    }

    @media screen and (max-width: 767px) {
      padding: 30px;
      top: 25px;
    }
    @media screen and (max-width: 480px) {
      padding: 20px 15px;
    }
  }

  &__title {
    font-family: $rubik-font400;
    font-weight: 800;
    color: #12426D;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: 0 30px;
    @media screen and (max-width: 767px) {
      font-size: 27px;
      font-weight: 32px;
    }
  }

  .massage {
    font-family: $rubik-font400;
    font-weight: 800;
    color: #12426D;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: 20px 30px 0;
    @media screen and (max-width: 767px) {
      font-size: 20px;
      font-weight: 25px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    position: relative;
    z-index: 1;

    &.hidden {
      display: none;
    }

    label {
      margin-bottom: 15px;

      p {
        font-family: $jet-font400;
        font-weight: 700;
        color: #12426D;
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 7px;

        &.error {
          color: #fff;
          background: #12426D;
          padding: 5px;
        }
      }
    }

    input {
      width: 100%;
      height: 65px;
      border: 1px #12426D solid;
      padding: 22px;
      font-size: 18px;
      line-height: 1.3;
      font-family: $rubik-font400;
    }

    button {
      width: 100%;
      height: 65px;
      background: #12426D;
      color: #fff;
      font-size: 18px;
      line-height: 1.3;
      font-family: $rubik-font400;
      font-weight: 700;
      border: none;
      transition: 0.3s;
      border: 1px #12426D solid;

      &:hover {
        background: #fff;
        color: #12426D;
      }
    }

    div {
      display: flex;
      justify-content: center;
      margin-top: 24px;

      p, a {
        font-size: 18px;
        line-height: 1.3;
        font-family: $rubik-font400;
        color: #12426D;


      }

      a {
        padding-left: 10px;
        color: #FF8A00;

        &:hover {
          transform: scale(1.05);
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        a {
          margin-top: 5px;
          padding: 0;
        }
      }
    }
  }


  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 26px;
    line-height: 15px;
    color: #CCD2D8;
    cursor: pointer;
    z-index: 3;
    @media screen and (min-width: 1024px) {
      &:hover {
        color: #12426D;
      }
    }
  }

}
