.logo {
  display: flex;
  width: 20%;
  padding: 20px;
  align-items: center;
  text-decoration: none;

  span {
    display: block;
    margin-right: 15px;
    img {
      width: 48px;
      height: 48px;
    }
  }

  p {
    font-size: 1rem;
    line-height: 20px;
    font-family: $jet-font400;
    font-weight: 700;
    color: #12426d;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  @media screen and (max-width: 1370px) {
    p {
      font-size: 0.9rem;
    }
  }
}

.burger {
  min-width: 40px;
  height: 40px;
  display: none;
  border: none;
  outline: none;
  background: none;
  overflow: hidden;
  margin: 0 10px;

  span {
    width: 80%;
    height: 3px;
    transform: none;
    opacity: 1;
    margin: 5px auto;
    display: block;
    transition: 0.6s;
    background: #12426d;
  }

  @media screen and (max-width: 1024px) {
    display: block;
    order: 4;
    align-self: center;
  }

  &.active {
    span:first-of-type {
      transform: rotate(45deg) translate(6px, 6px);
    }

    span:nth-of-type(2) {
      opacity: 0;
    }

    span:last-of-type {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }
}

.header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;

  &__wrapper {
    border-top: 1px solid #a7bed2;
    border-bottom: 1px solid #a7bed2;
    display: flex;

    & > a,
    & > ul,
    & > div {
      border-right: 1px solid #a7bed2;
    }
  }

  &--wrap-menu {
    display: flex;
    align-items: center;
    width: 65%;
  }

  &__menu {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    width: 100%;
    transition: 0.5s;
    position: relative;

    li {
      // position: relative;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // height: 100%;
      margin: 0 10px;
      white-space: nowrap;
    }
  }

  a {
    text-decoration: none;
    color: #12426d;
    font-family: $jet-font500;
    font-size: 16px;
    @media screen and (min-width: 1025px) {
      &:hover {
        color: #ff8a00;

        &:before {
          border-top-color: #ff8a00;
        }
      }
    }
  }

  &__dropdown-btn,
  &__lang-btn {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      right: -7px;
      top: 50%;
      transform: translateY(-50%);
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid #12426d;
      transition: 0.3s;
    }
  }

  &__lang-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 5px;
    width: 6%;
    position: relative;
    background: #fff;
    text-transform: uppercase;

    ul {
      width: 100%;
      top: calc(100% + 1px);
      z-index: 6;
      height: 100%;
      transition: 0s;
      display: none;
      position: absolute;
      background: #fff;

      li {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        color: #12426d;
        border-bottom: 1px solid #a7bed2;

        &:last-of-type {
          border: none;
        }
      }
    }
  }

  &__sign-in {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 5px;
    background: #fff;
    border: none;
    transition: 0.3s;
    width: 10%;

    span {
      display: flex;
      align-items: flex-end;
      overflow: hidden;
    }

    p {
      margin-left: 15px;
      font-size: 1rem;
      line-height: 20px;
      font-family: $jet-font500;
      color: #12426d;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover span p {
      color: #ff8a00;
    }
  }

  &__dropdown-menu {
    display: none;
    width: 260px;
    margin-left: auto;
    border-top: 1px solid #a7bed2;
    position: absolute;
    top: 100%;
    // top: calc(100% + 20px);
    // right: 0;
    z-index: 10;
    transition: 0s;

    li {
      padding: 10px 15px;
      border-bottom: 1px solid #a7bed2;
      background: #fff;
      margin: 0;

      &:nth-of-type(6),
      &:nth-of-type(7),
      &:nth-of-type(8) {
        display: block;
      }

      &:last-of-type {
        border: none;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__menu {
      li {
        a {
          font-size: 14px !important;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    * {
      border-color: #bfbfbf;
    }
    .logo {
      width: 100%;
      padding: 10px;
    }
    &__lang-box {
      padding: 20px 0;
      min-width: 60px;
    }
    &__sign-in {
      display: none;
    }
    &--wrap-menu {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 73px;
      left: -100%;
      width: 100%;
      z-index: 10;
      height: calc(100% - 73px);
      background: #fff;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 15px 20px;
      @media screen and (max-width: 416px) {
        top: 82px;
        height: calc(100% - 82px);
      }

      &.active {
        left: 0;
        overflow-y: scroll;
      }

      .mobile {
        width: 100%;
      }
    }
    &__menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: #fff;
      align-items: flex-start;
      padding: 0;
      justify-content: flex-start;

      li {
        margin: 0;
        // height: auto;
        width: 100%;
        border-bottom: 1px solid #a7bed2;
        background: #fff;
        // &:last-of-type {
        //     border: none;
        // }
        a {
          display: block;
          padding: 20px 0;
          width: 100%;
        }
      }
    }
    &__dropdown-btn {
      transition: 0s;

      &:before {
        right: 5px;
      }

      &.active {
        font-size: 14px !important;
        transform: none;

      }
    }
    &__dropdown-menu {
      padding: 5px 0;
      position: static;
      // position: relative;
      // top: auto;
      transform: none;
      width: 100%;

      li {
        padding: 10px;
        border: none;
        transform: none;

        a {
          padding: 0;
        }
      }
    }
    li:nth-of-type(6),
    li:nth-of-type(7),
    li:nth-of-type(8) {
      display: block;
    }
    li &__sign-in {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      padding: 20px 0;
    }
  }
  @media screen and (max-width: 767px) {
    .logo {
      max-width: 85%;
    }
    &__mail {
      display: none;
    }
  }
}

.mobile {
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
}

.desktop {
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
}
